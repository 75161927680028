import { memo, useState } from "react";
import { Avatar, Col, Row, Tooltip } from "antd";
import { IWorkOrderMessagesProps } from "./WorkOrderPMDetails.interface";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import "./WorkOrderPMDetails.scss";
import {
  displayDateTime,
  getFullUserName,
  isAudioFileType,
} from "utils/helpers";
import { IUser } from "redux/components/Auth";
import AlectifyAudioPlayer from "components/shared/preview-file/AlectifyAudioPlayer";
import { ICommentItem } from "components/shared/comments/Comments.interface";
import { downloadSingleFile } from "services/documents/documents.service";
import { CloudDownloadOutlined } from "@ant-design/icons";

const WorkOrderMessages: React.FC<IWorkOrderMessagesProps> = (
  props: IWorkOrderMessagesProps,
) => {
  const user = useSelector((state: IRootState) => state.auth.user);
  const isCurrentUser = (author: IUser | undefined) => author?.id === user?.id;

  const [transcriptVisibility, setTranscriptVisibility] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleTranscriptVisibility = (commentId: string) => {
    setTranscriptVisibility((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const defaultUser: IUser = {
    id: "",
    first_name: "unknown",
    last_name: "",
    email: "",
  };

  const getAuthorDisplayName = (author: IUser, isSystem: boolean) => {
    return isSystem
      ? `SYSTEM MESSAGE`
      : `${getFullUserName(author)} ${isCurrentUser(author) ? "(You)" : ""}`;
  };

  const renderAuthor = (sentBy: any, isSystem: boolean) => (
    <span className={`author-title ${isSystem ? "system-author" : ""}`}>
      {getAuthorDisplayName(sentBy, isSystem)}
    </span>
  );

  const renderAttachment = (comment: ICommentItem) =>
    isAudioFileType(comment.file_name) ? (
      <Row>
        <Col span={24} className="audio-player-main">
          <div className="attachment-audio-container ">
            <AlectifyAudioPlayer
              fileName={comment.s3_key}
              filePath={comment.s3_url}
              isSigned
            />
          </div>
          <div className="ml-5">
            {comment.speech_transcript && (
              <>
                <div
                  className="show-transcript-text text-link"
                  onClick={() => toggleTranscriptVisibility(comment.id)}
                >
                  {transcriptVisibility[comment.id]
                    ? "Hide Transcript"
                    : "Show Transcript"}
                </div>
              </>
            )}
          </div>
        </Col>
        <div
          className={`transcript-content ${
            transcriptVisibility[comment.id] ? "visible" : ""
          }`}
        >
          {comment.speech_transcript}
        </div>
      </Row>
    ) : (
      <Tooltip title="Download" placement="right">
        <div
          className="comment-attachment d-flex text-ellipsis"
          onClick={() => downloadSingleFile(comment.s3_key, comment.file_name)}
        >
          <CloudDownloadOutlined className="icon-size-15" />
          <span className="ml-10">{comment.file_name}</span>
        </div>
      </Tooltip>
    );

  return (
    <div>
      {props.comments?.map((comment, index) => (
        <div key={index} className="history-container">
          <div className="first-row-history">
            <div className="avatar-container-history">
              <Avatar src={comment?.sent_by?.image} />
              <p>
                <span
                  className={`user-name-container ${
                    comment.is_system_generated ? "system-comment" : ""
                  }`}
                >
                  {renderAuthor(comment.sent_by, comment.is_system_generated)}
                </span>
              </p>
            </div>

            <span className="date-time-container">
              {displayDateTime(comment.created_at, true)}
            </span>
          </div>
          <div className="second-row-history">
            <div className="system-message-container">
              {comment.is_system_generated && (
                <span>
                  {getFullUserName(comment?.sent_by || defaultUser)}
                  {isCurrentUser(comment?.sent_by || defaultUser)
                    ? "(You): "
                    : ""}
                </span>
              )}
              <div dangerouslySetInnerHTML={{ __html: comment.text }} />
            </div>
            <div className="attachment-container">
              {comment.content_type === "attachment" &&
                renderAttachment(comment)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(WorkOrderMessages);
