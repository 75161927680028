import React from "react";
import { isEmpty } from "lodash";
import AlectifyText from "static/texts.json";
import { CalendarDateIcon, WarningIcon } from "components/icons";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import { Dropdown, Space, Tooltip } from "antd";
import { displayDateTime, extractUserFromTeamMembers } from "utils/helpers";
import AvatarGroup from "components/shared/avatar-group";
import {
  IIncidentDetail,
  IncidentReportTableProps,
  IProject,
} from "pages/incident-report/Incident.interface";
import AttachmentIcon from "components/icons/AttachmentIcon";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import IncidentReportsDocuments from "../IncidentReportsDocuments";
import { StatusEnum, TaskPriorityEnum } from "enums";
import { EllipsisOutlined } from "@ant-design/icons";
import StatusOpen from "components/shared/tags/StatusOpen";
import StatusClosed from "components/shared/tags/StatusClosed";
import "../IncidentReport.scss";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";

export const INCIDENT_STATUS = {
  [StatusEnum.OPEN]: (
    <StatusOpen text={AlectifyText.OPEN} color={"rgb(39, 160, 253)"} />
  ),
  [StatusEnum.CLOSED]: (
    <StatusClosed text={AlectifyText.CLOSED} color={"rgb(60, 172, 21)"} />
  ),
};

const IncidentReportsColumns = (props: IncidentReportTableProps) => {
  const { user } = useSelector((state: IRootState) => state.auth);
  const navigate = useNavigate();

  const getItems = (record: any) => [
    {
      key: "edit",
      label: (
        <div
          onClick={() => {
            if (record.status !== StatusEnum.CLOSED) {
              props.onEdit(record);
            }
          }}
          style={{
            cursor:
              record.status === StatusEnum.CLOSED ? "not-allowed" : "pointer",
          }}
        >
          {AlectifyText.EDIT}
        </div>
      ),
      disabled: record.status === StatusEnum.CLOSED,
    },
    {
      key: "delete",
      label: (
        <div
          onClick={() => {
            if (record.status !== StatusEnum.CLOSED) {
              props.onDeleteRecord(record.id);
            }
          }}
          style={{
            cursor:
              record.status === StatusEnum.CLOSED ? "not-allowed" : "pointer",
          }}
        >
          {AlectifyText.DELETE}
        </div>
      ),
      disabled: record.status === StatusEnum.CLOSED,
    },
    {
      key: "statusUpdate",
      label: (
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (record.status === StatusEnum.CLOSED) {
              props.onStatusUpdate(record.id, StatusEnum.OPEN);
            } else {
              props.onStatusUpdate(record.id, StatusEnum.CLOSED);
            }
          }}
        >
          {record.status === StatusEnum.CLOSED
            ? AlectifyText.REOPEN
            : AlectifyText.CLOSE}
        </div>
      ),
    },
    /*     {
      key: "sendEmail",
      label: (
        <div
          onClick={() => {
            ModalServiceInstance.open(AlectifyModal, {
              name: MODAL_NAMES.INCIDENT_REPORT_EMAIL,
              title: AlectifyText.SEND_EMAIL,
              width: 700,
              onCancel: () =>
                ModalServiceInstance.close(AlectifyModal, {
                  name: MODAL_NAMES.INCIDENT_REPORT_EMAIL,
                }),
              footer: null,
              children: (
                <AlectifyEmailComposer
                  type={EmailTypes.INCIDENT_REPORT}
                  defaultBodyValue={createEmailBodyForIncidentReport(
                    record,
                    user,
                  )}
                  callback={() => {
                    ModalServiceInstance.close(AlectifyModal, {
                      name: MODAL_NAMES.INCIDENT_REPORT_EMAIL,
                    });
                    props.fetchAllIncidentReports();
                  }}
                />
              ),
            });
          }}
        >
          Send Email
        </div>
      ),
    }, */
  ];

  const onCell = (record: any, rowIndex: number) => ({
    style: {
      cursor: "pointer",
    },
    onClick: () => {
      navigate(`${ROUTES.INCIDENT_REPORTS_DETAIL.replace(":id", record.id)}`);
    },
  });
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      key: "incidentDate",
      dataIndex: "incidentDate",
      sorter: true,
      sortingColumn: "incidentDate",
      visible: true,
      title: AlectifyText.INCIDENT_DATE,
      width: "10%",
      ellipsis: true,
      onCell,
      render: (incidentDate: Date) => (
        <div className="creator-container">
          <CalendarDateIcon />
          <span className="creator-name-date">
            {(incidentDate && displayDateTime(incidentDate, false)) || "-"}
          </span>
        </div>
      ),
    },
    {
      key: "project",
      dataIndex: "project",
      visible: true,
      title: AlectifyText.SITE,
      width: "10%",
      ellipsis: true,
      onCell,
      render: (project: IProject) => (
        <Tooltip title={project?.name}>
          <span className="site-name">{project?.name}</span>
        </Tooltip>
      ),
    },
    {
      key: "incidentNo",
      dataIndex: "incidentNo",
      width: "12%",
      visible: true,
      onCell,
      title: AlectifyText.INCIDENT_NUMBER,
      render: (id: string, record: any) => (
        <>
          <span className="incident-id">{id || "-"}</span>
        </>
      ),
    },
    {
      key: "title",
      dataIndex: "title",
      title: "Title",
      width: "50%",
      ellipsis: true,
      visible: true,
      searchable: true,
      onCell,
      render: (title: string, record: any) => (
        <div className="title-container">
          <span className="title">
            <span className="text">{title}</span>
            {record?.priority === TaskPriorityEnum.CRITICAL && (
              <div className="icon">
                <Tooltip title={TaskPriorityEnum.CRITICAL}>
                  <WarningIcon height={12} width={17} />
                </Tooltip>
              </div>
            )}
          </span>
        </div>
      ),
    },

    {
      key: "team",
      dataIndex: "teamMembers",
      visible: true,
      title: AlectifyText.TEAM_MEMBERS,
      width: "10%",
      onCell,
      render: (_: any, record: any) => {
        return (
          <Space direction="vertical" size={5}>
            <AvatarGroup
              maxCount={2}
              showImage
              users={extractUserFromTeamMembers(
                !isEmpty(record?.teamMembers)
                  ? record.teamMembers
                  : record?.team?.projectTeamMembers,
              )}
            />
          </Space>
        );
      },
    },

    {
      key: "status",
      dataIndex: "status",
      visible: true,
      title: "Status",
      width: "10%",
      onCell,
      render: (status: any, record: IIncidentDetail) => {
        return <>{INCIDENT_STATUS[status as StatusEnum] || "-"}</>;
      },
    },

    {
      key: "documents",
      dataIndex: "documents",
      title: "Attachment",
      width: "7%",
      ellipsis: true,
      visible: true,
      onCell,
      render: (documents) => {
        const openAttachmentsDrawer = (e: any) => {
          e.stopPropagation();
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 860,
            closeIcon: true,
            title: AlectifyText.ATTACHMENTS,
            name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
              });
            },
            children: (
              <IncidentReportsDocuments
                details={documents}
                onDeleteDocuments={props.onDeleteDocuments as any}
              />
            ),
          });
        };
        return (
          <div
            className="attachment-count-container"
            onClick={openAttachmentsDrawer}
          >
            <AttachmentIcon />
            <span>{documents?.length || 0}</span>
          </div>
        );
      },
    },

    {
      key: "actions",
      dataIndex: "actions",
      visible: true,
      title: AlectifyText.ACTIONS,
      align: "center",
      width: "5%",
      render: (_: any, record: any) => (
        <Dropdown menu={{ items: getItems(record) }}>
          <EllipsisOutlined
            rotate={90}
            className="alectify-action-icon cursor-pointer"
          />
        </Dropdown>
      ),
    },
  ];

  return React.useMemo(() => columns.filter((cols) => cols.visible), []);
};

export default IncidentReportsColumns;
