import { Space, Tag } from "antd";
import { SmallCalendarIcon } from "components/icons";

const DateTag: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Tag color={"#9db9c2"} className="round-tag">
      <Space size={3} align="center">
        <SmallCalendarIcon /> {text || "-"}
      </Space>
    </Tag>
  );
};

export default DateTag;
