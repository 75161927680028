import {
  PM_STATUS,
  PME_ACTION_REQUIRED_ENUM,
  TaskPriorityEnum,
  TaskStatusEnum,
} from "enums";
import {
  ApprovedTag,
  ApproverTag,
  AssigneeTag,
  CompletedTag,
  ScheduledTag,
  WaitingForApprovalTag,
  WaitingForReviewTag,
} from "../tags";
import AlectifyText from "static/texts.json";
import DangerTag from "../tags/DangerTag";
import StampCheckIcon from "components/icons/StampCheckIcon";
import { DONE_TASK_GREEN_COLOR } from "../phone-number/constants";
import { IProcedure } from "services/procedures/procedures.interface";
import { ReactNode } from "react";
import ClosedTag from "../tags/ClosedTag";

export const TASK_PROIRITY_COMPONENTS = {
  [TaskPriorityEnum.NORMAL]: <CompletedTag text={AlectifyText.NORMAL} />,
  [TaskPriorityEnum.CRITICAL]: <DangerTag text={AlectifyText.CRITICAL} />,
};

export const PM_DETAIL_PRIORITY_COMPONENTS = {
  [TaskPriorityEnum.NORMAL]: null,
  [TaskPriorityEnum.CRITICAL]: <DangerTag text={AlectifyText.CRITICAL} />,
};

export const ACTION_REQUIRED_COMPONENTS = (
  action: PME_ACTION_REQUIRED_ENUM | null,
) => {
  switch (action) {
    case PME_ACTION_REQUIRED_ENUM.ASSIGNEE:
      return <AssigneeTag text={action} />;
    case PME_ACTION_REQUIRED_ENUM.APPROVERS:
      return <ApproverTag />;
    default:
      return <></>;
  }
};

export const TASK_STATUS_COMPONENTS = {
  [TaskStatusEnum.PENDING]: <ScheduledTag />,
  [TaskStatusEnum.APPROVED]: <ApprovedTag />,
  [TaskStatusEnum.SCHEDULED]: <ScheduledTag />,
  [TaskStatusEnum.COMPLETED]: <ClosedTag />,
  [TaskStatusEnum.REVIEW_DECLINED]: <WaitingForReviewTag />,
  [TaskStatusEnum.WAITING_FOR_REVIEW]: <WaitingForReviewTag />,
  [TaskStatusEnum.WAITING_FOR_APPROVAL]: <WaitingForApprovalTag />,
};
export const taskStatusStepIcon = (
  currentStatus: string,
  statusToMove: string,
): ReactNode => {
  if (
    currentStatus === PM_STATUS.PENDING &&
    statusToMove === PM_STATUS.WAITING_FOR_REVIEW
  ) {
    return AlectifyText.SUBMIT_REVIEW;
  } else if (
    currentStatus === PM_STATUS.INPROGRESS &&
    statusToMove === PM_STATUS.WAITING_FOR_REVIEW
  ) {
    return AlectifyText.SUBMIT_REVIEW;
  } else if (
    currentStatus === PM_STATUS.PENDING &&
    statusToMove === PM_STATUS.INPROGRESS
  ) {
    return AlectifyText.IN_PROGRESS;
  } else if (
    currentStatus === PM_STATUS.WAITING_FOR_REVIEW &&
    statusToMove === PM_STATUS.COMPLETED
  ) {
    return AlectifyText.REVIEW_COMPLETE;
  }
  return <></>;
};

export const getTaskStatusIcon = (procedure: IProcedure): JSX.Element => {
  const isAllChecked =
    procedure.procedureStepCheckedTotalCount ===
    procedure.procedureStepTotalCount;
  return isAllChecked ? (
    <StampCheckIcon fill={DONE_TASK_GREEN_COLOR} />
  ) : (
    <StampCheckIcon />
  );
};
