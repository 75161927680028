import { Col, Row, Skeleton, Spin, Statistic, Typography } from "antd";
import { IConfirmationProps } from "./Confirmation.interface";
import AlectifyText from "static/texts.json";
import {
  calculateDaysDifference,
  displayDateTime,
  getFullUserName,
  renderAmount,
} from "utils/helpers";
import { isEmpty, truncate } from "lodash";
import { useEffect, useState } from "react";
import { fetchExternalPMDetails } from "services/pm-external/pm-external.service";
import ChecklistStatus from "../procedure-detail/ChecklistStatus";
import AlectifyTable from "../table";
import PreviewFile from "../preview-file";
import "./style.scss";
import { ITask } from "redux/components/tasks";
import { MESSAGES } from "constants/messages";

const ReviewConfirmationModal: React.FC<IConfirmationProps> = ({
  pmExternal,
  listView,
  onCancel,
  onConfirm,
}: IConfirmationProps) => {
  const [workOrder, setWorkOrder] = useState<{
    data: ITask | null;
    fetching: boolean;
  }>({
    data: null,
    fetching: false,
  });

  useEffect(() => {
    const fetchWorkOrderDetail = async (id: string) => {
      setWorkOrder((prev) => ({ ...prev, fetching: true }));
      try {
        const response = await fetchExternalPMDetails(id);
        setWorkOrder({ data: response.data, fetching: false });
      } catch (error) {
        console.error(error);
        setWorkOrder((prev) => ({ ...prev, fetching: false }));
      }
    };

    if (pmExternal?.id) {
      fetchWorkOrderDetail(pmExternal.id);
    }
  }, [pmExternal?.id]);

  const renderStatistic = (
    title: string,
    value: string | number | null,
    fontSize = 14,
  ) => (
    <Statistic title={title} value={value || "-"} valueStyle={{ fontSize }} />
  );

  const renderTable = (documents: any[]) => (
    <AlectifyTable
      showSearch={false}
      columns={[
        {
          dataIndex: "fileName",
          title: "File Name",
          render: (value, record) => (
            <PreviewFile
              fileName={record.fileName}
              fileUrl={record.filePath}
              isActivevalue={record.isActive}
            />
          ),
        },
      ]}
      dataSource={documents}
      total={documents.length || 0}
      onDataFetch={() => {}}
      showPagination={true}
      defaultPageSize={5}
    />
  );

  const renderDetails = () => (
    <Row gutter={[20, 20]} className="card-container-review">
      <Col span={12}>
        {renderStatistic(
          AlectifyText.WORK_TITLE,
          truncate(pmExternal?.workTitle, { length: 20 }) || "-",
        )}
      </Col>
      <Col span={12}>
        {renderStatistic(
          AlectifyText.WORK_ID,
          truncate(pmExternal?.workId || "-"),
        )}
      </Col>
      <Col span={12}>
        {renderStatistic(
          AlectifyText.COMPLETED_BY,
          getFullUserName(workOrder.data?.reviewedBy as any),
        )}
      </Col>
      <Col span={12}>
        {renderStatistic(
          AlectifyText.COMPLETED_AT,
          displayDateTime(pmExternal?.reviewedAt, true),
        )}
      </Col>
      <Col span={12}>
        {renderStatistic(
          AlectifyText.DUEDATE,
          displayDateTime(pmExternal?.dueDate),
        )}
      </Col>
      <Col span={24} className="heading-review-confirm">
        <Typography.Text strong>
          {MESSAGES.HEADINGS.DETAIL_OF_WORK}
        </Typography.Text>
      </Col>
      <Col span={12}>
        {renderStatistic(
          AlectifyText.COMPLETION_DATE,
          displayDateTime(pmExternal?.completionAt),
        )}
      </Col>
      {pmExternal?.procedure && (
        <Col span={12}>
          <ChecklistStatus
            completedSteps={
              pmExternal.procedure.procedureStepCheckedTotalCount as any
            }
            totalSteps={pmExternal.procedure.procedureStepTotalCount || 0}
          />
        </Col>
      )}
      <Col span={12}>
        {renderStatistic(
          AlectifyText.ESTIMATED_HOURS,
          pmExternal?.estimatedHours ?? "-",
        )}
      </Col>
      <Col span={12}>
        {renderStatistic(
          AlectifyText.ESTIMATED_COST,
          pmExternal?.estimatedCost ?? "-",
        )}
      </Col>
      <Col span={12}>
        {renderStatistic(
          AlectifyText.SPARE_PARTS_USED,
          workOrder.data?.sparePartsUsed?.count ?? "-",
        )}
      </Col>
      <Col span={12}>
        {renderStatistic(
          AlectifyText.SPARE_PARTS_COST,
          renderAmount(workOrder.data?.sparePartsUsed?.cost),
        )}
      </Col>
      <Col span={24} className="heading-review-confirm">
        <Typography.Text strong>Comments & Attachments</Typography.Text>
      </Col>
      <Col span={24}>{workOrder.data?.reviewComment?.text || "-"}</Col>
      <Col span={24}>{renderTable(workOrder.data?.reviewDocuments || [])}</Col>
    </Row>
  );

  const renderSummary = () => (
    <Row className="card-container-review">
      <Typography.Text className="font-size-14 font-weight-bold mt-10 mb-5">
        Summary
      </Typography.Text>
      <Col span={24}>
        <ul>
          <li>
            <strong>
              {getFullUserName(workOrder.data?.reviewedBy as any)}
            </strong>{" "}
            completed the work on{" "}
            <strong>
              {displayDateTime(pmExternal?.reviewedAt, true) || "-"}
            </strong>{" "}
            [Completion date mentioned was{" "}
            <strong>{displayDateTime(pmExternal?.completionAt) || "-"}</strong>]
          </li>
          {workOrder.data?.completionAt && workOrder.data?.dueDate && (
            <li>
              The submission was{" "}
              <strong>
                {calculateDaysDifference(
                  workOrder.data?.completionAt,
                  workOrder.data?.dueDate,
                )}
              </strong>{" "}
              days late
            </li>
          )}
          <li>
            Estimated hours were{" "}
            <strong>{pmExternal?.estimatedHours || "N/A"}</strong> and estimated
            cost was <strong>{pmExternal?.estimatedCost || "N/A"}</strong>
          </li>
          {!isEmpty(workOrder.data?.sparePartsUsed?.count) && (
            <li>
              Total of <strong>{workOrder.data?.sparePartsUsed?.count}</strong>{" "}
              spare parts were used which costed{" "}
              <strong>{workOrder.data?.sparePartsUsed?.cost || "-"}</strong>
            </li>
          )}
          <li>Comments: {workOrder.data?.reviewComment?.text || "-"}</li>
        </ul>
      </Col>
      {!isEmpty(workOrder.data?.reviewDocuments) && (
        <>
          <Typography.Text className="font-size-14 font-weight-bold mt-10 mb-5">
            Attachments during submission
          </Typography.Text>
          <Col span={24}>
            {renderTable(workOrder.data?.reviewDocuments || [])}
          </Col>
        </>
      )}
    </Row>
  );

  return (
    <Spin spinning={workOrder.fetching}>
      {workOrder.fetching ? (
        <Skeleton active />
      ) : listView ? (
        renderSummary()
      ) : (
        renderDetails()
      )}
    </Spin>
  );
};

export default ReviewConfirmationModal;
