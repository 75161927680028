import { MULTIPART_HEADERS, pmHttp } from "utils/client";
import {
  DELETE_DOCUMENT_ID,
  GET_AFFECTED_SYSTEMS,
  GET_INCIDENT_IMPACTS,
  INCIDENT_COMMENT,
  INCIDENT_REPORT_ID,
  INCIDENT_REPORT_STATUS,
  INCIDENT_REPORTS,
} from "./incident-reports.endpoints";
import { IParams } from "redux/interfaces";
import { AxiosResponse } from "axios";
import {
  IIncidentAffectSystemResponse,
  IIncidentComment,
  IIncidentCommentResponse,
  IIncidentImpactResponse,
  IncidentDetailResponse,
} from "./incidnent-reports.interface";

/**
 * Gets incident report details.
 *
 * @param id - The ID of the incident report.
 * @returns A Promise resolving to the detail of incident report.
 * @throws An error if the request fails.
 */
export const getIncidentDetails = async (id: string) => {
  try {
    const response: AxiosResponse<IncidentDetailResponse> = await pmHttp.get(
      INCIDENT_REPORT_ID.replace("{id}", id),
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllIncidentReports = async (params?: IParams) => {
  try {
    const response = await pmHttp.get(INCIDENT_REPORTS, params);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const createIncidentReport = async (payload?: FormData) => {
  try {
    const response = await pmHttp.post(
      INCIDENT_REPORTS,
      payload,
      MULTIPART_HEADERS,
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateIncidentReports = async (id: string, payload: FormData) => {
  try {
    const response = await pmHttp.patch(
      INCIDENT_REPORT_ID.replace("{id}", id),
      payload,
      MULTIPART_HEADERS,
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteIncidentReports = async (id: string) => {
  try {
    const response = await pmHttp.delete(
      INCIDENT_REPORT_ID.replace("{id}", id),
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteIncidentDocument = async (id: string) => {
  try {
    const response = await pmHttp.delete(
      DELETE_DOCUMENT_ID.replace("{id}", id),
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateIncidentReportStatus = async (
  id: string,
  payload: {
    status: string;
  },
) => {
  try {
    const response = await pmHttp.patch(
      INCIDENT_REPORT_STATUS.replace("{id}", id),
      payload,
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Gets Impact list for impact dropdown to create incident report.
 *
 * @param projectId - The ID of the project to get the impact list.
 * @returns A Promise resolving to the list of impacts.
 * @throws An error if the request fails.
 */
export const getIncidentImpacts = async (projectId: string) => {
  try {
    const response: AxiosResponse<IIncidentImpactResponse> = await pmHttp.get(
      GET_INCIDENT_IMPACTS.replace("{projectId}", projectId),
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Gets affected systems list for impact dropdown to create incident report.
 *
 * @param projectId - The ID of the project to get the affected system list.
 * @returns A Promise resolving to the list of affected systems.
 * @throws An error if the request fails.
 */
export const getAffectedSystems = async (projectId: string) => {
  try {
    const response: AxiosResponse<IIncidentAffectSystemResponse> =
      await pmHttp.get(GET_AFFECTED_SYSTEMS.replace("{projectId}", projectId));
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Creates Commnet for incident report.
 *
 * @param { string } id - The ID of the incident report.
 * @param { IIncidentComment } body - payload consisting of comments information.
 * @returns A Promise resolving to the list of commnets.
 * @throws An error if the request fails.
 */

export const createNewIncidentComment = async (id: string, body: FormData) => {
  try {
    const response: AxiosResponse<IIncidentCommentResponse> = await pmHttp.post(
      INCIDENT_COMMENT.replace("{id}", id),
      body,
      MULTIPART_HEADERS,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Gets Commnets list for incident report
 *
 * @param { string } id - The ID of the incident report.
 * @param { IParams } params - Optional parameters for the request.
 * @returns A Promise resolving to the list of commnets.
 * @throws An error if the request fails.
 */

export const getAllIncidentComments = async (id: string, params?: IParams) => {
  try {
    const response: AxiosResponse<IIncidentCommentResponse> = await pmHttp.get(
      INCIDENT_COMMENT.replace("{id}", id),
      params,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};
