import { memo, useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { getDateWiseStats } from "services/data-round/data-round.service";
import {
  ICalendarEventsState,
  IDataRoundsCalendarProps,
} from "./DataRoundsReports.interface";
import DataRoundsEvents from "./DataRoundsEvents";
import DataRoundsCalendarToolbar from "./DataRoundsCalendarToolbar";
import { Spin } from "antd";
import "./DataRoundsReports.scss";

const DataRoundsCalendar: React.FC<IDataRoundsCalendarProps> = (
  props: IDataRoundsCalendarProps,
) => {
  const localizer = momentLocalizer(moment);

  const [dateRange, setDateRange] = useState({
    start_date: moment().startOf("month").format("YYYY-MM-DD"),
    end_date: moment().endOf("month").format("YYYY-MM-DD"),
  });

  const [calendarEvents, setCalendarEvents] = useState<ICalendarEventsState>({
    data: [],
    fetching: false,
  });

  useEffect(() => {
    fetchDateWiseStats();
  }, [dateRange]);

  const fetchDateWiseStats = async () => {
    try {
      setCalendarEvents((prev: ICalendarEventsState) => ({
        ...prev,
        fetching: true,
      }));
      if (props.projectId) {
        const params = {
          start_date: dateRange.start_date,
          end_date: dateRange.end_date,
        };
        const response = await getDateWiseStats(props.projectId, params);
        setCalendarEvents((prev: ICalendarEventsState) => ({
          ...prev,
          data: response.data,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCalendarEvents((prev: ICalendarEventsState) => ({
        ...prev,
        fetching: false,
      }));
    }
  };

  const events = useMemo(
    () =>
      calendarEvents?.data?.map((event) => ({
        ...event,
        start: new Date(event.shift_date),
        end: new Date(event.shift_date),
      })),
    [calendarEvents.data],
  );

  const eventPropGetter = () => {
    return {
      className: "customClassEventPropGetter",
    };
  };

  const CustomEventComponent = (eventProps: any) => (
    <DataRoundsEvents {...eventProps} projectId={props.projectId} />
  );

  return (
    <div className="data-rounds-calendar">
      <Spin spinning={calendarEvents.fetching}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          views={["month"]}
          defaultView="month"
          eventPropGetter={eventPropGetter}
          components={{
            event: CustomEventComponent,
            toolbar: DataRoundsCalendarToolbar,
          }}
          popup
          dayLayoutAlgorithm="no-overlap"
          onNavigate={(date) => {
            setDateRange({
              start_date: moment(date).startOf("month").format("YYYY-MM-DD"),
              end_date: moment(date).endOf("month").format("YYYY-MM-DD"),
            });
          }}
        />
      </Spin>
    </div>
  );
};

export default memo(DataRoundsCalendar);
