import React, { memo, useState, useEffect, useRef } from "react";
import { Breadcrumb, Col, message, Row, Space } from "antd";
import AlectifyText from "static/texts.json";
import { useNavigate } from "react-router-dom";
import IncidentReportTable from "components/incident-report/IncidentReportTable";
import { ROUTES } from "routes/Routes.constants";
import {
  deleteIncidentDocument,
  deleteIncidentReports,
  getAllIncidentReports,
  updateIncidentReportStatus,
} from "services/incident-reports/incident-reports.service";
import {
  IAllIncidentReportState,
  IIncidentDetail,
} from "pages/incident-report/Incident.interface";
import IncidentDetail from "components/incident-report/IncidentDetail";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";
import { useForm } from "antd/lib/form/Form";
import { MESSAGES } from "constants/messages";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ConfirmationModal from "components/shared/confirmation/Confirmation";
import IncidentReportCreateEdit from "./incident-report-creation/IncidentReportCreateEdit";
import "./IncidentReport.scss";
import { WarningIcon } from "components/icons";
import AlectifyButton from "components/shared/button";

const IncidentReport: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );

  const {
    auth: { user },
  } = useSelector((state: IRootState) => state);

  const activeMasterProjectRef = useRef(activeMasterProject);

  const [isDetailPageActive, setDetailPageActive] = useState(false);
  const [currentIncident, setCurrentIncident] =
    useState<IIncidentDetail | null>(null);
  const [selectedIncidentId, setSelectedIncidentId] = useState<string | null>(
    null,
  );

  const [reportState, setReportState] = useState<IAllIncidentReportState>({
    data: [],
    fetching: false,
    meta: {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
  });
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null,
  );

  const editModeRef = useRef<{ active: boolean; id: string }>({
    active: false,
    id: "",
  });

  useEffect(() => {
    activeMasterProjectRef.current = activeMasterProject;
  }, [activeMasterProject]);

  useEffect(() => {
    loadIncidentReports();
    closeIncidentDetail();
  }, [activeMasterProject]);

  const loadIncidentReports = async (paginationOptions?: IPagination) => {
    const { per_page, ...restOptions } = paginationOptions || {};
    const params = {
      projectId: activeMasterProjectRef.current?.id,
      page: paginationOptions?.page || PAGINATION.DEFAULT_START_PAGE,
      limit: paginationOptions?.limit || PAGINATION.DEFAULT_PAGE_SIZE,
      ...restOptions,
      teamMembers: [user?.id],
      createdById: user.id,
    };

    setReportState((prevState) => ({ ...prevState, fetching: true }));

    try {
      const response = await getAllIncidentReports(params as any);
      setReportState((prevState) => ({
        ...prevState,
        data: response?.data,
        meta: response?.meta,
        fetching: false,
      }));
    } catch (error) {
      console.error(error);
      setReportState((prevState) => ({ ...prevState, fetching: false }));
    }
  };
  const handleCreateIncident = () => {
    editModeRef.current.active = false;
    openCreationModal(false, "");
  };

  const handleDeleteIncident = async (id: string) => {
    try {
      const response = await deleteIncidentReports(id);
      if (response) {
        message.success(MESSAGES.INCIDENT_REPORTS.DELETE);
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.INCIDENT_REMOVE_MODAL,
        });
        loadIncidentReports();
      } else {
        message.error(MESSAGES.API_RESPONSE_ERRORS.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDetailPageActive(false);
    }
  };

  const openDeleteConfirmationModal = (id: string) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.INCIDENT_REMOVE_MODAL,
      title: "Delete Report",
      onOk: () => {
        handleDeleteIncident(id);
      },
      footer: null,
      closable: false,
      children: (
        <ConfirmationModal
          onConfirm={() => handleDeleteIncident(id)}
          message="Are you sure you want to remove this report?"
          icon={WarningIcon}
          onCancel={() =>
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.INCIDENT_REMOVE_MODAL,
            })
          }
        />
      ),
    });
  };

  const handleEditIncident = (record: IIncidentDetail) => {
    openCreationModal(true, record.id);
  };

  const handleIncidentStatusChange = async (id: string, newStatus: string) => {
    try {
      if (!isEmpty(id)) {
        const payload = {
          status: newStatus,
        };
        const response = await updateIncidentReportStatus(id, payload);
        if (response.status) {
          message.success(MESSAGES.INCIDENT_REPORTS.STATUS_UPDATED);
        } else {
          message.error(MESSAGES.API_RESPONSE_ERRORS.SOMETHING_WENT_WRONG);
        }

        loadIncidentReports();
      }
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.INCIDENT_STATUS_MODAL,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const showStatusConfirmationModal = (id: string, newStatus: string) => {
    const statusText = newStatus === "CLOSED" ? "Close" : "Reopen";
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.INCIDENT_STATUS_MODAL,
      title: `${statusText} Report`,
      footer: null,
      closable: false,
      children: (
        <ConfirmationModal
          onConfirm={() => handleIncidentStatusChange(id, newStatus)}
          message={`Are you sure you want to ${statusText.toLowerCase()} this report?`}
          onCancel={() =>
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.INCIDENT_STATUS_MODAL,
            })
          }
        />
      ),
    });
  };

  const handleIncidentDetail = (record: IIncidentDetail) => {
    setDetailPageActive(true);
    setCurrentIncident(record);
  };

  const closeIncidentDetail = () => {
    setDetailPageActive(false);
    setSelectedIncidentId(null);
  };

  const handleDocumentDelete = async (id: string) => {
    try {
      if (!isEmpty(id)) {
        const response = await deleteIncidentDocument(id);
        if (response.status) {
          message.success(MESSAGES.INCIDENT_REPORTS.FILE_DELETED);
        }
        loadIncidentReports();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onModalClose = () => {
    form.resetFields();
    setValidationMessage(null);
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.INCIDENT_REPORT_CREATION_MODAL,
    });
  };

  const modalConfig = (editing: boolean, id: string) => {
    return {
      name: MODAL_NAMES.INCIDENT_REPORT_CREATION_MODAL,
      className: MODAL_NAMES.INCIDENT_REPORT_CREATION_MODAL,
      title: `${editing ? "Update" : "Create"} Incident Report`,
      width: 800,
      style: { top: 30 },
      okText: "Submit",
      onOk: form.submit,
      destroyOnClose: true,
      onCancel: onModalClose,
      footer: (
        <Space>
          {validationMessage && (
            <span className="alectify-wo-validation-message">
              {validationMessage}
            </span>
          )}
          <AlectifyButton
            text={AlectifyText.CANCEL}
            type="default"
            className="alectify-default-button"
            onClick={onModalClose}
          />
          <AlectifyButton
            text={"Submit"}
            type="primary"
            onClick={async () => {
              try {
                await form.validateFields();
                setValidationMessage(null);
                form.submit();
              } catch (ex: any) {
                if (!isEmpty(ex.errorFields)) {
                  if (ex.errorFields.length > 1) {
                    setValidationMessage(
                      MESSAGES.WORK_ORDER.MULTIPLE_FIELDS_VALIDATION,
                    );
                  } else {
                    setValidationMessage(ex.errorFields[0].errors[0]);
                  }
                }
              }
            }}
          />
        </Space>
      ),
      children: (
        <IncidentReportCreateEdit
          FormInstance={form}
          editing={editing}
          incidentReportId={id}
          callback={() => loadIncidentReports()}
        />
      ),
    };
  };
  const openCreationModal = (editing: boolean, id: string) => {
    ModalServiceInstance.open(AlectifyModal, modalConfig(editing, id));
  };
  useEffect(() => {
    ModalServiceInstance.updateModalProps(
      MODAL_NAMES.INCIDENT_REPORT_CREATION_MODAL,
      modalConfig(false, ""),
    );
  }, [validationMessage]);

  return (
    <div className="incident-report-main">
      <div className="breadcrumb-container">
        <Breadcrumb
          items={[
            {
              title: AlectifyText.HOME,
              href: "#",
              onClick: () => navigate(ROUTES.PROJECT),
            },
            { title: AlectifyText.INCIDENT_REPORTS },
          ]}
        />
      </div>
      <div className="content-container">
        <Row gutter={12} className="height-100">
          <Col span={isDetailPageActive ? 12 : 24} className="report-table">
            <IncidentReportTable
              allReports={reportState}
              onEdit={handleEditIncident}
              openCreateIncident={handleCreateIncident}
              selectedRowId={selectedIncidentId}
              setSelectedRowId={setSelectedIncidentId}
              onDeleteRecord={openDeleteConfirmationModal}
              onDeleteDocuments={handleDocumentDelete}
              onStatusUpdate={showStatusConfirmationModal}
              handleIncidentDetail={handleIncidentDetail}
              fetchAllIncidentReports={loadIncidentReports}
            />
          </Col>
          <Col
            span={12}
            className={`detail-page ${
              isDetailPageActive ? "active-detail-page" : ""
            }`}
          >
            {isDetailPageActive && (
              <IncidentDetail
                detail={currentIncident}
                closeIncidentDetail={closeIncidentDetail}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default memo(IncidentReport);
