import { Col, Collapse, Row, Space, Tooltip, Typography } from "antd";
import { IIncidentCommentProps } from "./IncidentDetails.interface";
import { useForm } from "antd/lib/form/Form";
import AvatarGroup from "components/shared/avatar-group";
import { displayDateTime } from "utils/helpers";
import IncidentCommentBox from "./IncidentCommentBox";
import { isEmpty } from "lodash";
import "../incident-report-creation/IncidentReportCreateEdit.scss";
import { StatusEnum } from "enums";
import PreviewFile from "components/shared/preview-file";
import TextEditor from "components/text-editor/TextEditor";

const IncidentComments: React.FC<IIncidentCommentProps> = ({
  comments,
  incidentId,
  meta,
  incidentDetails,
  collapse,
  setCollapse,
  getComments,
}) => {
  const [FormInstance] = useForm();

  return (
    <Row justify={"start"} align={"top"}>
      <Typography.Title level={5} className="mt-0">
        Comments & Emails ({meta?.totalItems || 0})
      </Typography.Title>

      <Col
        span={24}
        className="alectify-wo-create-edit-container incident-comment-reply-container"
      >
        {incidentDetails?.status === StatusEnum.CLOSED ? (
          <></>
        ) : (
          <Collapse
            onChange={(e: string | string[]) => setCollapse(!collapse)}
            items={[
              {
                key: "1",
                label: (
                  <span className="incident-comment-reply-title">
                    Send Reply
                  </span>
                ),
                children: (
                  <IncidentCommentBox
                    FormInstance={FormInstance}
                    incidentId={incidentId || ""}
                    callback={() => {
                      getComments(incidentId || "");
                    }}
                    commentsCount={meta?.totalItems || 0}
                    incidentDetails={incidentDetails}
                  />
                ),
              },
            ]}
            defaultActiveKey={["1"]}
          />
        )}
      </Col>

      <Col span={24} className="incident-detail-comment-list">
        <div>
          {comments?.map((comment) => (
            <>
              <Row key={comment.id} className="mb-10">
                <Col span={12} className="">
                  <Space>
                    <AvatarGroup
                      size={30}
                      users={comment?.createdBy ? [comment?.createdBy] : []}
                    />
                    {(!isEmpty(comment?.createdBy) && (
                      <span className="font-size-12">{`${
                        comment?.createdBy?.first_name || "N/A"
                      } ${comment?.createdBy?.last_name || ""}`}</span>
                    )) || <span className="font-size-12">External User</span>}
                  </Space>
                </Col>

                <Col span={12} className="text-align-right date-fonts">
                  {displayDateTime((comment?.createdAt as any) || "", true)}
                </Col>

                <Col
                  span={24}
                  className="incident-detail-comment-reply-content"
                >
                  {comment.incidentSummary && (
                    <>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: comment.incidentSummary,
                        }}
                      />
                    </>
                  )}
                  <Space>
                    {comment?.callAt && (
                      <>
                        <span>
                          {" "}
                          <strong>Time of Call:</strong>
                        </span>
                        <span className="incident-statistic-card-helper-text">
                          {displayDateTime(
                            (comment?.callAt as any) || "",
                            true,
                          )}
                        </span>
                      </>
                    )}
                    {comment?.nextUpdateAt && (
                      <>
                        <span className="ml-20">
                          <strong>Next Update:</strong>
                        </span>
                        <span className="incident-statistic-card-helper-text">
                          {displayDateTime(
                            (comment?.nextUpdateAt as any) || "",
                            true,
                          )}
                        </span>
                      </>
                    )}
                  </Space>
                  {!isEmpty(comment?.actions) && (
                    <>
                      <div className="mt-5">
                        <span>
                          <strong>Actions(s):</strong>
                        </span>
                      </div>

                      <TextEditor
                        readOnly
                        initialValue={comment?.actions}
                        customClass="editor-render-only-content"
                      />
                    </>
                  )}
                  {comment?.systemState && (
                    <>
                      <div className="mt-5">
                        <span>
                          <strong>System State:</strong>
                        </span>
                      </div>

                      <TextEditor
                        readOnly
                        initialValue={comment?.systemState}
                        customClass="editor-render-only-content"
                      />
                    </>
                  )}

                  {comment?.description && comment?.description !== "." && (
                    <>
                      <div className="mt-5">
                        <span>
                          <strong>Description:</strong>
                        </span>
                      </div>

                      <TextEditor
                        readOnly
                        initialValue={comment?.description}
                        customClass="editor-render-only-content"
                      />
                    </>
                  )}
                  {!isEmpty(comment.files) && (
                    <>
                      <strong>Attachments:</strong>
                      <Space wrap className="ml-5">
                        {comment?.files?.map((file: any) => (
                          <Tooltip title={file.fileName} placement="right">
                            <PreviewFile
                              fileName={file?.fileName}
                              fileUrl={file?.filePath}
                              isActivevalue={file?.isActive}
                            />
                          </Tooltip>
                        ))}
                      </Space>
                    </>
                  )}
                </Col>
              </Row>
            </>
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default IncidentComments;
