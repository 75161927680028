import { Breadcrumb, Col, Form, Row, Spin, message } from "antd";
import AlectifyText from "static/texts.json";
import AutoCreateProcedureResponse from "components/auto-create-procedure/AutoCreateProcedureResponse";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";
import { useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  setAiProcedureResponse,
  setBase64UrlAction,
} from "redux/components/chat-bot/sources";
import { fetchTopFiveAnswers } from "services/chat-bot/chatBot.service";
import { processSources } from "components/chatbot-ui/Chatbot.helper";
import { IAiProcedureState } from "components/chatbot-ui/Chatbot.interface";
import { SERVICE_UNIQUE_ID } from "utils/constants";
import { cancelLlmApiRequests } from "utils/client";
import { IAutoCreateForm } from "components/auto-create-procedure/AutoCreateProcedure.interface";
import AutoCreateWelcome from "components/auto-create-procedure/AutoCreateWelcome";
import AutoCreateProcedureForm from "components/auto-create-procedure/AutoCreateProcedureFom";
import { IRootState } from "redux/rootReducer";
import { deleteUserNameSpace } from "services/procedures/procedures.service";
import { MESSAGES } from "constants/messages";
import type { RcFile } from "antd/es/upload";
import { actions } from "redux/components/chat-bot";
import "./style.scss";

const AutoCreateProcedure: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const aiProcedureState = useSelector((state: any) => state.chatbot);
  const [state, setState] = useState<IAiProcedureState>(aiProcedureState);
  const [formValues, setFormValues] = useState<IAutoCreateForm | null>(null);
  const user = useSelector((state: IRootState) => state.auth.user);
  const fileRef = useRef<RcFile | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getQuestionResponse = async (values: IAutoCreateForm) => {
    form.setFieldValue("description", values.description);
    try {
      setFormValues(values);

      // Create custom question
      const customQuestion = `What is the ${values.question} for ${values.description}?`;

      setState((prevState: IAiProcedureState) => {
        const newState: IAiProcedureState = {
          ...prevState,
          aiProcedureItems: {
            ...(prevState?.aiProcedureItems ?? {}),
            toggle: true,
            fetching: true,
            data: [
              ...(prevState?.aiProcedureItems?.data ?? []),
              { role: "user", content: customQuestion },
              { role: "assistant", temporary: true },
            ],
          },
        };
        dispatch(setAiProcedureResponse(newState));
        return newState;
      });
      await getQueryResponse(values);
    } catch (err) {
      console.error(err);
    } finally {
      setState((prevState: IAiProcedureState) => ({
        ...prevState,
        aiProcedureItems: {
          ...prevState.aiProcedureItems,
          fetching: false,
        },
      }));
    }
    form.setFieldValue("description", values.description);
  };

  const getUploadedFile = (file: any) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target?.result as any;
      dispatch(setBase64UrlAction(fileContent));
    };
    reader.readAsDataURL(file);
  };

  const getQueryResponse = async (payload: any) => {
    try {
      const aiProcedureMetaData: any = {
        messages:
          aiProcedureState?.aiProcedureItems?.data?.map((item: any) => ({
            role: item?.role,
            content: item?.content,
          })) || [],
        userId: user?.id,
      };

      aiProcedureMetaData.messages.push({
        role: "user",
        content: payload?.question,
      });

      const response = await fetchTopFiveAnswers(aiProcedureMetaData);

      if (response) {
        setState((prevState: IAiProcedureState) => {
          const { aiProcedureItems } = prevState;
          const updatedData = [
            ...aiProcedureItems?.data?.slice(0, -1),
            {
              role: "assistant",
              content: response?.data?.content,
              sources: processSources(response),
            },
          ];

          const newState: IAiProcedureState = {
            ...prevState,
            aiProcedureItems: {
              ...aiProcedureItems,
              data: updatedData,
              fetching: false,
            },
          };

          dispatch(setAiProcedureResponse(newState));
          return newState;
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      form.setFieldValue("description", null);
      setState((prevState: IAiProcedureState) => ({
        ...prevState,
        aiProcedureItems: {
          ...prevState.aiProcedureItems,
          fetching: false,
        },
      }));
    }
  };

  useEffect(() => {
    if (aiProcedureState.aiProcedureItems?.data.length) {
      const lastItem = aiProcedureState?.aiProcedureItems?.data?.slice(-1)[0];
      if (lastItem && lastItem.temporary) {
        setState((prevState: IAiProcedureState) => {
          const { aiProcedureItems } = prevState;
          const updatedData = aiProcedureItems?.data?.slice(0, -2);
          const newState: IAiProcedureState = {
            ...prevState,
            aiProcedureItems: {
              ...aiProcedureItems,
              data: updatedData,
              fetching: false,
            },
          };
          return newState;
        });
      }
    }
    return () => {
      cancelLlmApiRequests(SERVICE_UNIQUE_ID.TOP_5_SERVICE_API);
    };
  }, []);

  const onClearAll = async () => {
    try {
      setIsLoading(true);
      const payload = {
        userId: user?.id,
      };
      const response = await deleteUserNameSpace(payload);
      if (response.success) {
        message.success(MESSAGES.GENERAL_MESSAGES.DATA);
      }
    } catch (err) {
      console.log(err);
      message.warning(MESSAGES.GENERAL_MESSAGES.No_DATA);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      form.resetFields();
      fileRef.current = null;
      dispatch(setBase64UrlAction(null));
      dispatch(actions.resetAiProcedureState());
      setState((prevState: IAiProcedureState) => ({
        ...prevState,
        aiProcedureItems: {
          fetching: false,
          toggle: false,
          data: [],
        },
      }));
    }
  };

  const removeUploadedFile = () => {
    form.setFieldValue("attachFile", null);
    fileRef.current = null;
    setState((prevState: IAiProcedureState) => ({
      ...prevState,
      aiProcedureItems: {
        ...prevState.aiProcedureItems,
      },
    }));
  };
  return (
    <Spin spinning={isLoading}>
      <div className="ai-page-container">
        <Breadcrumb
          className="mb-10"
          items={[
            {
              title: AlectifyText.HOME,
              href: `#`,
              onClick: () => navigate(`${ROUTES.PROJECT}`),
            },

            {
              title: AlectifyText.AUTO_CREATE_PROCEDURE,
            },
          ]}
        />

        <span className="title-left">{AlectifyText.AUTO_CREATE_PROCEDURE}</span>

        <Row className="height-100" gutter={16}>
          <Col span={6}>
            <div className="ai-col-container">
              <AutoCreateProcedureForm
                form={form}
                state={state}
                fileRef={fileRef}
                onClearAll={onClearAll}
                setIsLoading={setIsLoading}
                onSubmit={getQuestionResponse}
                getUploadedFile={getUploadedFile}
              />
            </div>
          </Col>
          <Col span={18} className="height-100">
            <div className="height-100">
              {!isEmpty(state?.aiProcedureItems?.data) ? (
                <AutoCreateProcedureResponse
                  state={state}
                  fileRef={fileRef}
                  formValues={formValues}
                  removeUploadedFile={removeUploadedFile}
                />
              ) : (
                <AutoCreateWelcome />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default AutoCreateProcedure;
