import { memo, useEffect, useState } from "react";
import { IRootState } from "redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import AlectifyText from "static/texts.json";
import DocumentsAttachment from "../documents-attachment/DocumentsAttachment";
import { ITaskAttachmentProps } from "../task-attachment/TaskAttachment.interface";
import {
  deletPrevMaintenanceDocuments,
  fetchPrevMaintenanceDocuments,
  uploadPrevMaintenanceDocuments,
} from "services/pm-internal/pm-internal.service";
import { MESSAGES } from "constants/messages";
import { getPmInternalAttachments } from "redux/components/pm-internal/sources";
import { IPagination } from "../table/AlectifyTable.interface";
import { IMeta, IMetaNest, IParams } from "redux/interfaces";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import { MODAL_NAMES } from "constants/modal.constants";
import AlectifyModal from "../modal";
import {
  getComments,
  setUploadDocuments,
} from "redux/components/common/sources";
import { FOLDER_NAME } from "enums/folder-type.enum";
import { actions, IPmAttachmentDocuments } from "redux/components/pm-internal";
import { isEmpty } from "lodash";
import { UploadFile } from "antd/lib";
import { IDocuments } from "redux/components/documents";

const PreventiveDocumentsAttachment: React.FC<ITaskAttachmentProps> = (
  props: ITaskAttachmentProps,
) => {
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState<IPmAttachmentDocuments[]>([]);
  const [meta, setMeta] = useState<IMeta>();
  const [loader, setLoader] = useState<boolean>(false);

  /*   const fetchPmAttachments = (params?: IPagination) => {
    if (!isEmpty(params)) {
      params.limit = params.per_page;
    }
    dispatch(getPmInternalAttachments(props.details.id, params as IParams));
    // if (props.callback) props.callback();
  }; */

  const fetchAllDocuments = async (params?: IPagination) => {
    setLoader(true);
    try {
      const response = await fetchPrevMaintenanceDocuments(
        props.details.id,
        params as IParams,
      );
      if (response.status) {
        setLoader(false);
        setDocuments(response.data);
        setMeta(response.meta);
      }
      if (props.callback) props.callback();
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  const user = useSelector((state: IRootState) => state.auth);
  // const [directory, setDirectory] = useState<{ [key: string]: string }>({});

  /*   const onDirectoryChange = (uid: string, dir: string) => {
    const newDir = directory;
    newDir[uid] = dir;
    setDirectory(newDir);
  }; */

  const onCloseModal = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.DOCUMENT_ATTACHMENTS,
    });
  };

  useEffect(() => {
    fetchAllDocuments({
      page: 1,
      per_page: 50,
      limit: 50,
    });
    onCloseModal();
  }, [props.details.id]);

  const uploadDocument = async (fileList: UploadFile[]) => {
    try {
      // dispatch(actions.setPmInternalLoader(true));
      setLoader(true);

      message.loading(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_UPLOADING);

      // Create FormData and append files
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append(FOLDER_NAME.DOCUMENT_UPLOAD, file.originFileObj as any);
      });

      // Append additional fields to formData
      formData.append("userId", user?.user?.id);
      formData.append("projectId", props.details.project?.id);
      formData.append("subProjectId", props.details.subProject.id);

      // Call API to upload documents
      await uploadPrevMaintenanceDocuments(props.details.id, formData);
      message.success(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_UPLOADED);
      fetchAllDocuments({
        page: 1,
        per_page: 50,
        limit: 50,
      });
      if (props.isDetailPage) {
        dispatch(getComments(props.details.subProject.id, props.details.id));
      }
      onCloseModal();
      setLoader(false);
    } catch (error) {
      message.error(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_ERROR);
      throw error;
    } finally {
      // dispatch(actions.setPmInternalLoader(false));
      setLoader(false);
    }
  };

  const deleteDocument = async (id: string) => {
    try {
      setLoader(true);
      await deletPrevMaintenanceDocuments(id);
      setLoader(false);
      fetchAllDocuments();
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };
  return (
    <>
      <DocumentsAttachment
        uploadDocuments={uploadDocument}
        loading={loader}
        items={documents}
        count={meta?.totalItems || 0}
        shareable={props.shareable}
        documentType={AlectifyText.PM}
        id={props?.details?.id}
        deleteDocument={deleteDocument}
      />
    </>
  );
};
export default memo(PreventiveDocumentsAttachment);
