import React from "react";
import { Avatar, Space, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import AlectifyText from "static/texts.json";
import { displayDateTime, getFullUserName } from "utils/helpers";
import { UserIcon } from "components/icons";
import DateTag from "components/shared/tags/DateTag";
import "../WorkOrder.styles.scss";

interface IMyWorkOrderColumnsProps {
  showPmType?: boolean;
  showOverDueTag?: boolean;
  myWorkOrder?: boolean;
  isTaskType?: boolean;
  onCell?: any;
}

const MyWorkOrderColumns = (props?: IMyWorkOrderColumnsProps) => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const { user } = useSelector(({ auth }: IRootState) => auth);

  // const onMasterProjectClick = (masterProject: IMasterProject) => {
  //   if (!isExternalUser(user)) {
  //     dispatch(setActiveMasterProject(masterProject));
  //     navigate(`${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject.id}`);
  //   }
  //   DrawerServiceInstance.close(AlectifyDrawer, {
  //     name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
  //   });
  // };

  // const onSubProjectClick = (
  //   masterProject: IMasterProject,
  //   subProject: ISubProject,
  // ) => {
  //   if (!isExternalUser(user)) {
  //     dispatch(setActiveSubProject(subProject));
  //     dispatch(setActiveMasterProject(masterProject));
  //     navigate(
  //       `${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject?.id}${ROUTES.SUB_PROJECT}/${subProject.id}`,
  //     );
  //     DrawerServiceInstance.close(AlectifyDrawer, {
  //       name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
  //     });
  //   }
  // };

  return React.useMemo<ColumnProps<any>[] | ColumnGroupType<any>[] | any[]>(
    () =>
      [
        {
          title: AlectifyText.CREATED,
          key: "created",
          dataIndex: "created_at",
          visible: !props?.isTaskType,
          ellipsis: true,
          width: 130,
          onCell: props?.onCell,
          render: (target: any, record: any) => {
            return (
              <div className="d-flex justify-content-space-between">
                {/* <div className="status-column">
                  {record?.priority === TaskPriorityEnum.CRITICAL ? (
                    <div className="critical-text">{AlectifyText.CRITICAL}</div>
                  ) : null}
                </div> */}
                <Space direction="vertical" size={5}>
                  <div className="creator-container">
                    {isEmpty(record?.createdBy?.image_url) ? (
                      <UserIcon />
                    ) : (
                      <Tooltip
                        title={`${
                          record?.created_by && !isEmpty(record?.created_by)
                            ? getFullUserName(record?.created_by[0])
                            : record?.createdBy && !isEmpty(record?.createdBy)
                            ? getFullUserName(record?.createdBy)
                            : "-"
                        }`}
                      >
                        <Avatar
                          src={
                            record?.createdBy?.image_url &&
                            record?.createdBy?.image_url
                          }
                          size={"default"}
                        />
                      </Tooltip>
                    )}
                    {/* <span className="creator-name-date">
                      {`${
                        record?.created_by && !isEmpty(record?.created_by)
                          ? getFullUserName(record?.created_by[0])
                          : record?.createdBy && !isEmpty(record?.createdBy)
                          ? getFullUserName(record?.createdBy)
                          : "-"
                      }`}
                    </span> */}
                  </div>

                  <div className="creator-container">
                    <DateTag
                      text={`${displayDateTime(
                        record?.created_at || record?.createdAt,
                        false,
                        false,
                      )}`}
                    />
                  </div>
                </Space>
              </div>
            );
          },
        },

        {
          title: AlectifyText.CREATED,
          key: "created",
          dataIndex: "created_at",
          visible: props?.isTaskType,
          ellipsis: true,
          width: 120,
          onCell: props?.onCell,
          render: (target: any, record: any) => {
            return (
              <>
                {/* <div className="status-column">
                  {record?.priority === TaskPriorityEnum.CRITICAL ? (
                    <div className="critical-text">{AlectifyText.CRITICAL}</div>
                  ) : null}
                </div> */}
                <Space direction="vertical" size={5}>
                  <div className="creator-container">
                    {isEmpty(record?.createdBy?.image_url) ? (
                      <UserIcon />
                    ) : (
                      <Tooltip
                        title={`${
                          record?.created_by && !isEmpty(record?.created_by)
                            ? getFullUserName(record?.created_by[0])
                            : record?.createdBy && !isEmpty(record?.createdBy)
                            ? getFullUserName(record?.createdBy)
                            : "-"
                        }`}
                      >
                        <Avatar
                          src={
                            record?.createdBy?.image_url &&
                            record?.createdBy?.image_url
                          }
                          size={"default"}
                        />
                      </Tooltip>
                    )}
                    {/* <span className="creator-name-date">
                      {`${
                        record?.created_by && !isEmpty(record?.created_by)
                          ? getFullUserName(record?.created_by[0])
                          : record?.createdBy && !isEmpty(record?.createdBy)
                          ? getFullUserName(record?.createdBy)
                          : "-"
                      }`}
                    </span> */}
                  </div>

                  <div className="creator-container">
                    <DateTag
                      text={`${displayDateTime(
                        record?.created_at || record?.createdAt,
                        false,
                        false,
                      )}`}
                    />
                  </div>
                </Space>
              </>
            );
          },
        },
        // {
        //   key: "master-project",
        //   dataIndex: "project",
        //   title: AlectifyText.SITE_AND_ASSET_CATEGORY,
        //   width: 100,
        //   ellipsis: {
        //     showTitle: false,
        //   },
        //   visible: true,
        //   onCell: props?.onCell,
        //   render: (target: any, record: any) => {
        //     const masterProject = record?.projects
        //       ? get(record, "projects[0]", target)
        //       : record?.project;
        //     const subProject = record?.sub_projects
        //       ? get(record, "sub_projects[0]", target)
        //       : record?.subProject;
        //     return (
        //       <Space direction="vertical" size={15}>
        //         {masterProject?.name ? (
        //           <Tooltip placement="topLeft" title={masterProject?.name}>
        //             <Space direction="horizontal" size={8}>
        //               <AlectifyBulletIcon />
        //               {(!isExternalUser(user) && (
        //                 <TextToLink
        //                   className="text-to-link-options"
        //                   text={truncate(masterProject?.name, {
        //                     length: 20,
        //                     omission: "...",
        //                   })}
        //                   underline={!isExternalUser(user)}
        //                   onClick={(e) => {
        //                     e.stopPropagation();
        //                     DrawerServiceInstance.open(AlectifyDrawer, {
        //                       width: 420,
        //                       name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
        //                       title: masterProject?.name,
        //                       closable: true,
        //                       closeIcon: <CloseOutlined />,
        //                       onClose: () =>
        //                         DrawerServiceInstance.close(AlectifyDrawer, {
        //                           name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
        //                         }),
        //                       children: (
        //                         <SwitchSubProjectDrawer
        //                           masterProjectId={masterProject.id}
        //                           navigate={navigate}
        //                         />
        //                       ),
        //                       customFooter: (
        //                         <AlectifyButton
        //                           text={AlectifyText.VISIT_SITE}
        //                           className="ant-btn-primary alectify-btn-block "
        //                           block
        //                           onClick={() =>
        //                             onMasterProjectClick(masterProject)
        //                           }
        //                         />
        //                       ),
        //                     });
        //                   }}
        //                 />
        //               )) ||
        //                 truncate(masterProject?.name, {
        //                   length: 20,
        //                   omission: "...",
        //                 })}
        //             </Space>
        //           </Tooltip>
        //         ) : (
        //           "-"
        //         )}
        //         <Tooltip placement="topLeft" title={subProject?.name}>
        //           <Space direction="horizontal" size={8}>
        //             <AlectifyBulletIcon />
        //             {truncate(subProject?.name, {
        //               length: 20,
        //               omission: "...",
        //             })}
        //           </Space>
        //         </Tooltip>
        //       </Space>
        //     );
        //   },
        // },
      ].filter((column) => column.visible),
    [],
  );
};

export default MyWorkOrderColumns;
