import parse, {
  domToReact,
  HTMLReactParserOptions,
  DOMNode,
} from "html-react-parser";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  paragraph: {
    marginBottom: 10,
    fontSize: 12,
  },
  heading: {
    fontSize: 18,
    marginBottom: 20,
    marginTop: 20,
  },
  list: {
    marginBottom: 10,
  },
  listItem: {
    flexDirection: "row",
    marginBottom: 10,
  },
  orderedBullet: {
    marginRight: 10,
  },
  bullet: {
    width: 5,
    height: 5,
    borderRadius: 2.5,
    backgroundColor: "black",
    marginRight: 5,
    marginTop: 3,
  },
  code: {
    fontFamily: "Courier",
    fontSize: 12,
    backgroundColor: "#f5f5f5",
    padding: 5,
    borderRadius: 3,
  },
  bold: {
    fontWeight: "bold",
  },
});

const parseInlineStyles = (styleString: string) => {
  const styleObject: { [key: string]: string | number } = {};
  styleString.split(";").forEach((style) => {
    const [key, value] = style.split(":");
    if (key && value) {
      styleObject[key.trim()] = value.trim();
    }
  });
  return styleObject;
};

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode.type === "tag") {
      const { name, children, attribs } = domNode;
      const inlineStyles = attribs?.style
        ? parseInlineStyles(attribs.style)
        : {};

      switch (name) {
        case "p":
          return (
            <Text style={{ ...styles.paragraph, ...inlineStyles }}>
              {domToReact(children as DOMNode[], options)}
              {"\n"}
            </Text>
          );
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
        case "h6":
          return (
            <Text style={{ ...styles.heading, ...inlineStyles }}>
              {domToReact(children as DOMNode[], options)}
              {"\n"}
            </Text>
          );
        case "ul":
          return (
            <View style={{ ...styles.list, ...inlineStyles }}>
              {domToReact(children as DOMNode[], options)}
            </View>
          );
        case "ol":
          return (
            <View style={{ ...styles.list, ...inlineStyles }}>
              {domToReact(children as DOMNode[], options)}
            </View>
          );
        case "li":
          const isOrdered = (domNode.parent as any)?.name === "ol";
          return (
            <View style={styles.listItem}>
              {isOrdered ? (
                <Text style={styles.orderedBullet}>
                  {domNode.parent && domNode.parent.children
                    ? domNode.parent.children.indexOf(domNode) + 1
                    : 0}
                  .
                </Text>
              ) : (
                <View style={styles.bullet} />
              )}
              <Text>
                {domToReact(children as DOMNode[], options)} {"\n"}
              </Text>
            </View>
          );
        case "code":
          return (
            <Text style={{ ...styles.code, ...inlineStyles }}>
              {domToReact(children as DOMNode[], options)}
            </Text>
          );
        case "strong":
          return (
            <Text style={{ ...styles.bold, ...inlineStyles }}>
              {domToReact(children as DOMNode[], options)}
            </Text>
          );
        case "span":
          // Handle spans if they have specific attributes or children
          return (
            <Text style={inlineStyles}>
              {domToReact(children as DOMNode[], options)}
            </Text>
          );
        case "br":
          return <Text>{"\n"}</Text>;
        default:
          return domToReact(children as DOMNode[], options);
      }
    }
  },
};

const htmlToReactPdf = (html: string) => {
  return parse(html, options);
};

export default htmlToReactPdf;
