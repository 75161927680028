import { Col, Row, Space, Spin, Typography } from "antd";
import { IPMInformationCardProps } from "./Pmdetail.interface";
import { get, isEmpty } from "lodash";
import { NonRecurringTag, RecurringTag } from "../tags";
import {
  ACTION_REQUIRED_COMPONENTS,
  PM_STATUS_COMPONENTS,
} from "components/shared/tasks-table/effects/useTasksColumns";
import { displayDateTime, truncateText } from "utils/helpers";
import AvatarGroup from "../avatar-group";
import AlectifyText from "static/texts.json";
import { TASK_PROIRITY_COMPONENTS } from "../tasks-table/effects/useTasksColumns";
import { TaskPriorityEnum } from "enums";
import "./PmDetails.scss";
import { getPMEActionRequired } from "pages/work-order/WorkOrderCreateEdit.constants";

const PmInfoCard: React.FC<IPMInformationCardProps> = (
  props: IPMInformationCardProps,
) => {
  return (
    <Spin spinning={props.loader}>
      <div className="pm-detail-info-card-container mt-20">
        <Typography.Text className="pm-detail-info-card-header">
          Information
        </Typography.Text>
        <div className="pm-detail-info-card-body mb-20">
          <Row justify={"space-between"} align={"middle"}>
            <Col span={12} className="mb-15">
              Site
            </Col>
            <Col
              span={12}
              className="text-align-right mb-15 alectify-key-value"
            >
              {truncateText(props.pmData?.project?.name || "-", 20)}
            </Col>
            <Col span={12} className="mb-15">
              Asset Category
            </Col>
            <Col
              span={12}
              className="text-align-right mb-15 alectify-key-value"
            >
              {truncateText(props.pmData?.subProject?.name || "-", 20)}
            </Col>
            {!props.pmData?.isGeneric && (
              <>
                <Col span={12} className="mb-15">
                  {/* {!isEmpty(props.pmData?.area) ? "Parent Asset" : "Sub Asset"} */}
                </Col>
                <Col
                  span={12}
                  className="text-align-right mb-15 alectify-key-value"
                >
                  {/* {(!isEmpty(props.pmData?.area)
                    ? truncateText(props.pmData?.area?.name || "-", 20)
                    : truncateText(props.pmData?.asset?.name || "-", 20)) ||
                    "-"} */}
                </Col>
              </>
            )}
            {!props.isMasterPM && (
              <>
                <Col span={12} className="mb-15">
                  Work Order ID
                </Col>
                <Col
                  span={12}
                  className="text-align-right mb-15 alectify-key-value"
                >
                  {truncateText(props.pmData?.workId || "-", 20)}
                </Col>
              </>
            )}
            <Col span={12} className="mb-15">
              Frequency
            </Col>
            <Col span={12} className="text-align-right mb-15">
              {!isEmpty(props.pmData) && (
                <>
                  {props.pmData?.isRecurring ? (
                    <RecurringTag />
                  ) : (
                    <NonRecurringTag />
                  )}
                </>
              )}
            </Col>
            <Col span={12} className="mb-15">
              Priority
            </Col>
            <Col span={12} className="text-align-right">
              {TASK_PROIRITY_COMPONENTS[
                props.pmData?.priority as TaskPriorityEnum
              ] || "-"}
            </Col>
          </Row>
        </div>
        <Typography.Text className="pm-detail-info-card-header">
          Status
        </Typography.Text>
        <div className="pm-detail-info-card-body mb-20">
          <Row justify={"space-between"} align={"top"}>
            {!props.isMasterPM && (
              <>
                <Col span={12} className="mb-15">
                  Task Status
                </Col>
                <Col span={12} className="text-align-right mb-15">
                  {(!isEmpty(props.pmData) &&
                    PM_STATUS_COMPONENTS[props.pmData.status]) ||
                    "-"}
                </Col>
                <Col span={12} className="mb-15">
                  Action Required
                </Col>
                <Col span={12} className="text-align-right mb-15">
                  {(!isEmpty(props.pmData) &&
                    !isEmpty(
                      ACTION_REQUIRED_COMPONENTS(
                        getPMEActionRequired(props.pmData as any),
                      ),
                    ) &&
                    ACTION_REQUIRED_COMPONENTS(
                      getPMEActionRequired(props.pmData as any),
                    )) ||
                    "-" ||
                    "-"}
                </Col>
              </>
            )}
            <Col span={12} className="mb-15">
              Due Date
            </Col>
            <Col
              span={12}
              className="text-align-right mb-15 alectify-key-value"
            >
              {displayDateTime(get(props.pmData, "dueDate"))}
            </Col>
            {!props.isMasterPM && (
              <>
                <Col span={12}>Is Re-Opened ?</Col>
                <Col span={12} className="text-align-right alectify-key-value">
                  {props.pmData?.isReopened ? "Yes" : "No"}
                </Col>
              </>
            )}
          </Row>
        </div>
        <Typography.Text className="pm-detail-info-card-header">
          Assignments
        </Typography.Text>
        <div className="pm-detail-info-card-body mb-20 ">
          <Row justify={"space-between"} align={"middle"}>
            <Col span={12} className="mb-15">
              Assignees
            </Col>
            <Col span={12} className="text-align-right mb-15">
              <AvatarGroup
                maxCount={3}
                showImage
                users={props.pmData?.assignees?.map(({ user }) => user) || []}
                size="small"
              />
            </Col>
            <Col span={12} className="mb-15">
              Approvers
            </Col>
            <Col span={12} className="text-align-right mb-15">
              <AvatarGroup
                maxCount={3}
                showImage
                users={props.pmData?.approvers?.map(({ user }) => user) || []}
                size="small"
              />
            </Col>
            <Col span={12}>Team Members</Col>
            <Col span={12} className="text-align-right">
              <AvatarGroup
                maxCount={3}
                users={
                  (!isEmpty(props.pmData?.team) &&
                    props.pmData?.team.projectTeamMembers.map((d) => d.user)) ||
                  (!isEmpty(props.pmData?.teamMembers) &&
                    props.pmData?.teamMembers?.map((d: any) => d.user)) ||
                  []
                }
                size={"small"}
              />
            </Col>
          </Row>
        </div>
        {!isEmpty(props.pmData?.estimatedHours) && (
          <>
            <Typography.Text className="pm-detail-info-card-header">
              Submitted For Review
            </Typography.Text>
            <div className="pm-detail-info-card-body mb-20">
              <Row justify={"space-between"} align={"middle"}>
                <Col span={12} className="mb-15">
                  Estimated Hours
                </Col>
                <Col
                  span={12}
                  className="text-align-right mb-15 alectify-key-value"
                >
                  {props.pmData?.estimatedHours || "-"}
                </Col>
                <Col span={12} className="mb-15">
                  Estimated Cost
                </Col>
                <Col
                  span={12}
                  className="text-align-right alectify-key-value mb-15"
                >
                  {(props.pmData?.estimatedCost &&
                    `$${props.pmData?.estimatedCost}`) ||
                    "-"}
                </Col>
                <Col span={12}>Completion Date</Col>
                <Col span={12} className="text-align-right alectify-key-value">
                  {displayDateTime(props.pmData?.completionAt, false) || "-"}
                </Col>
              </Row>
            </div>
          </>
        )}
        {!props.isMasterPM && (
          <>
            <Typography.Text className="pm-detail-info-card-header">
              Other Information
            </Typography.Text>
            <div className="pm-detail-info-card-body mb-20">
              <Row justify={"space-between"} align={"middle"}>
                <Col span={10} className="mb-15">
                  Created
                </Col>
                <Col span={14} className="text-align-right mb-15">
                  <Space direction={"horizontal"}>
                    <span className="alectify-key-value">
                      {displayDateTime(props.pmData?.createdAt, true) || "-"}
                    </span>
                    {!isEmpty(props.pmData?.createdBy) && (
                      <AvatarGroup
                        showImage
                        users={
                          props.pmData?.createdBy
                            ? [props.pmData.createdBy]
                            : []
                        }
                        size="small"
                      />
                    )}
                  </Space>
                </Col>
                {/*  <Col span={14} className="mb-15">
              In Progress
            </Col>
            <Col span={10} className="text-align-right mb-15">
              <Space direction={"horizontal"}>
                {displayDateTime(props.pmData?.inProgressAt) || "-"}
                {!isEmpty(props.pmData?.inProgressBy) && (
                  <AvatarGroup
                    showImage
                    users={([props.pmData?.inProgressBy] || []) as any}
                    size="small"
                  />
                )}
              </Space>
            </Col> */}
                <Col span={10} className="mb-15">
                  {AlectifyText.SUBMITTED_REVIEW}
                </Col>
                <Col span={14} className="text-align-right mb-15">
                  <Space direction={"horizontal"}>
                    <span className="alectify-key-value">
                      {displayDateTime(props.pmData?.reviewedAt, true) || "-"}
                    </span>
                    {!isEmpty(props.pmData?.reviewedBy) && (
                      <AvatarGroup
                        showImage
                        users={
                          props.pmData?.reviewedBy
                            ? [props.pmData.reviewedBy]
                            : []
                        }
                        size="small"
                      />
                    )}
                  </Space>
                </Col>
                <Col span={10}>{AlectifyText.REVIEW_COMPLETED}</Col>
                <Col span={14} className="text-align-right">
                  <Space direction={"horizontal"}>
                    <span className="alectify-key-value">
                      {displayDateTime(props.pmData?.completedAt, true) || "-"}
                    </span>
                    {!isEmpty(props.pmData?.completedBy) && (
                      <AvatarGroup
                        showImage
                        users={
                          props.pmData?.completedBy
                            ? [props.pmData.completedBy]
                            : []
                        }
                        size="small"
                      />
                    )}
                  </Space>
                </Col>
              </Row>
            </div>
          </>
        )}
      </div>
    </Spin>
  );
};

PmInfoCard.defaultProps = {
  isMasterPM: false,
};
export default PmInfoCard;
