import React, { useEffect, useState } from "react";
import { Col, Divider, Form, Row, TimePicker, Typography } from "antd";
import {
  DataRoundDaysEnum,
  DataRoundFrequncyEnum,
  IDrProjectBasicInfoProps,
  IDrProjectCreationState,
} from "./DrProjectCreation.interface";
import AlectifyInput from "components/shared/input";
import AlectifyText from "static/texts.json";
import AlectifySelect from "components/shared/select";
import { MESSAGES } from "constants/messages";
import dayjs from "dayjs";
import AlectifyTable from "components/shared/table";
import { isEmpty } from "lodash";
import { IShiftSchedule } from "services/data-round/data-round.interface";
import "./DrCreateEdit.scss";
import { SHIFTS_PER_DAY } from "services/data-round/data-round.constants";
import { getDRProjectDetails } from "services/data-round/data-round.service";
import TeamMembersDropdown from "components/shared/team-members-dropdown";
import { IRootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import { DATA_ROUND_DAYS_NAME_SHORT } from "./DrProject.constants";

const DrProjectBasicInfo: React.FC<IDrProjectBasicInfoProps> = (
  props: IDrProjectBasicInfoProps,
) => {
  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );

  const [shifts, setShifts] = useState<IShiftSchedule[]>([]);
  const [startTime, setStartTime] = useState<string | null>(null); // Default start time (HH:mm)
  const [frequency, setFrequency] = useState<DataRoundFrequncyEnum>(1);
  const [selectedDays, setSelectedDays] = useState<DataRoundDaysEnum[]>([]); // Updated to store multiple days

  useEffect(() => {
    // Calculate shift times when shiftCount or startTime changes
    const calculateShifts = () => {
      if (props.shiftCount && startTime) {
        const shiftTimes: IShiftSchedule[] = [];
        let currentStartTime = dayjs(startTime, "HH:mm"); // Use the exact start time without rounding
        const hoursInShift = 24 / props.shiftCount; // Calculate hours per shift

        for (let i = 0; i < props.shiftCount; i++) {
          const startTimeFormatted = currentStartTime.format("HH:mm");
          const endTime = currentStartTime
            .add(hoursInShift, "hour")
            .subtract(1, "minute")
            .format("HH:mm");
          shiftTimes.push({
            name: `Shift ${i + 1}`,
            start_time: startTimeFormatted,
            end_time: endTime,
            frequency_type: 1,
          });

          // Move the start time for the next shift one minute after the current end time
          currentStartTime = dayjs(endTime, "HH:mm").add(1, "minute");
        }
        props.shiftScheduleRef.current = shiftTimes;
        setShifts(shiftTimes);
      } else {
        setShifts([]);
      }
    };

    calculateShifts();
  }, [props.shiftCount, startTime]);

  const handleTimeChange = (time: any, timeString: string) => {
    // Ensure timeString is in HH:mm format and round to nearest hour
    if (timeString) {
      const formattedTime = dayjs(timeString, "HH:mm").format("HH:mm");
      props.startTimeRef.current = formattedTime;
      props.FormInstance.setFieldValue(
        "start_time_first_shift",
        dayjs(timeString, "HH:mm"),
      );
      setStartTime(formattedTime);
    } else {
      setStartTime(null);
    }
  };

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "key",
      key: "key",
      render: (text: any, record: any, index: number) => index + 1,
    },
    {
      title: "Shift Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
    },
  ];

  const getDetails = async () => {
    try {
      props.setState((prev: IDrProjectCreationState) => {
        return {
          ...prev,
          loading: true,
        };
      });
      const response = await getDRProjectDetails(props.projectId || "");
      if (response.success) {
        props.setState((prev: IDrProjectCreationState) => {
          return {
            ...prev,
            project: response.data,
            loading: false,
          };
        });
        props.FormInstance.setFields([
          {
            name: "name",
            value: response.data?.name,
          },
          {
            name: "shifts_per_day",
            value: Number(response.data?.shifts_per_day),
          },
          {
            name: "start_time_first_shift",
            value: dayjs(response.data?.start_time_first_shift, "HH:MM"),
          },
          {
            name: "team",
            value: response.data?.team,
          },
          {
            name: "shiftFrequency",
            value: response.data?.frequency_type,
          },
        ]);
        if (response.data?.frequency_type === DataRoundFrequncyEnum.WEEKLY) {
          props.currentRoundIdRef.current =
            response.data?.shift_schedules[0].id || null;
          setSelectedDays(response.data?.shift_schedules[0].days_of_week || []);
        }
        setFrequency(response.data?.frequency_type);
        props.frequencyRef.current = response.data?.frequency_type;
        setShifts(response.data?.shift_schedules);
        props.setShiftCount &&
          props.setShiftCount(response.data?.shifts_per_day || null);
        handleTimeChange("", response.data?.start_time_first_shift);
      }
    } catch (ex) {
      props.setState((prev: IDrProjectCreationState) => {
        return {
          ...prev,
          loading: false,
        };
      });
      console.log(ex);
    }
  };
  useEffect(() => {
    if (!isEmpty(props.project)) {
      setShifts(props.project?.shift_schedules);
      setStartTime(props.project?.start_time_first_shift);
      props.setShiftCount &&
        props.setShiftCount(props.project?.shifts_per_day || 0);
    }
    if (props.isEditing) {
      getDetails();
    } else {
      props.FormInstance.setFieldValue("shifts_per_day", 1);
    }

    if (props.shiftCount && props.setShiftCount) {
      props.FormInstance.setFieldValue("shifts_per_day", props.shiftCount);
      props.setShiftCount(props.shiftCount);
    }
  }, []);

  const calculateShiftsPerHour = (shiftCount: number) => {
    if (!shiftCount) return 0;

    const shiftsPerHour = 24 / shiftCount;

    // Check if the number has a decimal part
    if (shiftsPerHour % 1 !== 0) {
      return shiftsPerHour.toFixed(1);
    } else {
      return shiftsPerHour;
    }
  };
  return (
    <div className="data-rounds-basic-info-main">
      <Form
        name="project-info-form"
        layout="vertical"
        form={props.FormInstance}
        // onFinish={onSubmitProfile}
      >
        <Row justify={"start"} align={"middle"} gutter={20}>
          <Col span={24}>
            <AlectifyInput
              name="name"
              placeholder="Project Name"
              type="text"
              label={AlectifyText.PROJECT_NAME}
              defaultValue={props.state.project?.name}
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.PROJECT_NAME,
                  ),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <AlectifySelect
              label="Frequency"
              options={[
                {
                  key: "daily",
                  label: "Daily",
                  value: 1,
                },
                {
                  key: "weekly",
                  label: "Weekly",
                  value: 2,
                },
                /*   {
                  key: "monthly",
                  label: "Monthly",
                  value: 3,
                }, */
              ]}
              name="shiftFrequency"
              onChange={(value) => {
                props.frequencyRef.current = value;
                setFrequency(value);
              }}
              defaultValue={frequency}
              disabled={props.isEditing}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              name="team"
              label="Select Team"
              className="team-member-item"
              rules={[
                {
                  required: true,
                  message: MESSAGES.TEAMS_MESSAGES.TEAM_REQUIRED,
                },
              ]}
            >
              <TeamMembersDropdown
                formItem={false}
                projectId={activeMasterProject?.id}
                showTeamOption={false}
                showOnlyTeams={true}
                className="team-member-item"
              />
            </Form.Item>
          </Col>

          {frequency === DataRoundFrequncyEnum.WEEKLY && (
            <Col span={24}>
              <Typography.Title level={5} className="m-0">
                Select Day(s)
              </Typography.Title>

              <div className="mt-15">
                <Form.Item
                  name={"days_of_week"}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        "Week Days",
                      ),
                    },
                  ]}
                >
                  {DATA_ROUND_DAYS_NAME_SHORT.map((day) => (
                    <span
                      className={`wo-alectify-days-name mr-5 ${
                        selectedDays.includes(day.value) ? "active" : ""
                      }`}
                      onClick={() => {
                        props.FormInstance.setFieldValue(
                          "days_of_week",
                          day.value,
                        );

                        const updatedSelectedDays = [...selectedDays]; // Create a copy of the state
                        const index = updatedSelectedDays.indexOf(day.value);

                        if (index > -1) {
                          updatedSelectedDays.splice(index, 1); // Remove the day if it exists
                        } else {
                          updatedSelectedDays.push(day.value); // Add the day if it doesn't exist
                        }
                        props.selectedDaysRef.current = updatedSelectedDays;
                        setSelectedDays(updatedSelectedDays); // Update the state with the new array
                      }}
                      key={day.value}
                    >
                      {day.label}
                    </span>
                  ))}
                </Form.Item>
              </div>
            </Col>
          )}
          {frequency === DataRoundFrequncyEnum.DAILY && (
            <>
              <Col span={12}>
                <AlectifySelect
                  label="Number of shifts per day"
                  options={SHIFTS_PER_DAY}
                  name="shifts_per_day"
                  onChange={(value) =>
                    props.setShiftCount && props.setShiftCount(Number(value))
                  }
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.SHIFTS_PER_DAY,
                      ),
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  label={AlectifyText.START_TIME_OF_FIRST_SHIFT}
                  name="start_time_first_shift"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.START_TIME_OF_FIRST_SHIFT,
                      ),
                    },
                  ]}
                >
                  <TimePicker
                    format={"HH:mm"}
                    defaultValue={
                      (props.state.project?.start_time_first_shift &&
                        dayjs(
                          props.state.project?.start_time_first_shift,
                          "HH:mm",
                        )) ||
                      null
                    }
                    className="project-form-time-picker"
                    size="large"
                    placeholder="HH:MM"
                    onChange={handleTimeChange}
                    onSelect={(value: any) => handleTimeChange(null, value)}
                    minuteStep={15} // You can keep this for UI consistency
                    showNow={false}
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        {frequency === DataRoundFrequncyEnum.DAILY && (
          <>
            <Divider className="mt-10 mb-10" />
            <Row justify={"start"} align={"middle"} className="mb-5">
              <Col span={24}>
                <Typography.Title level={4} className="mt-5">
                  Shift Details
                </Typography.Title>
              </Col>
              <Col span={24}>
                <Typography.Paragraph className="m-0">
                  Number of hours per shift is{" "}
                  <strong>
                    {calculateShiftsPerHour(props.shiftCount || 0)}
                  </strong>{" "}
                  hrs
                </Typography.Paragraph>
              </Col>
            </Row>
            <AlectifyTable
              columns={columns}
              dataSource={!isEmpty(shifts) && !isEmpty(startTime) ? shifts : []}
              total={0}
              onDataFetch={() => {}}
              showPagination={false}
            />
          </>
        )}
      </Form>
    </div>
  );
};

export default DrProjectBasicInfo;
