import { useEffect, useRef, useState } from "react";
import { truncateString } from "utils/helpers";
import {
  Alert,
  Empty,
  Form,
  FormInstance,
  message,
  Spin,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import { BASIC_REQUIRED_VALIDATION_RULES, PAGINATION } from "constants/index";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { ISelectedItems } from "redux/components/assets";
import { isEmpty } from "lodash";
import { getDocumentsCount } from "redux/components/documents/sources";
import { MESSAGES } from "constants/messages";
import {
  uploadAssetPackageDocuments,
  uploadAssetsDocuments,
  uploadProjectDocuments,
} from "services/documents/documents.service";
import AlectifyText from "static/texts.json";
import AlectifyButton from "../button";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import AssetsTable from "../assets-table";
import SelectMasterProject from "../master-project-select";
import SelectSubProject from "../sub-project-select/SelectSubProject";
import { IMasterProject } from "redux/components/master-project";
import { ISubProject } from "redux/components/sub-project";
import { IDocumentInitialState } from "./documents-table/DocumentsTable.interface";
import AlectifyCard from "../card";
import SelectedDocumentIcon from "components/icons/SelectedDocumentIcon";
import { useLocation } from "react-router-dom";
import "./AddDocument.scss";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import CrossRedIcon from "components/icons/CrossRedIcon";
interface IUploadDocumentFormProps {
  form: FormInstance;
  submitting: boolean;
  documentsToUpload: any[];
  setSubmitting: (loading: boolean) => void;
  getDocuments?: () => void;
  markDocumentsAsUploaded: (documents: any[]) => void;
  selectedDocuments: any[];
  setSelectedDocuments: (documents: any) => void;
  handleFilesUpload: (value: any) => void;
  removeSingleDocument: (value: string) => void;
}

interface IUploadDocumentParams {
  dir_key: string;
  category: string;
}

const UploadDocumentForm: React.FC<IUploadDocumentFormProps> = (
  props: IUploadDocumentFormProps,
) => {
  const initialState: IDocumentInitialState = {
    loading: false,
    selectedMasterProject: null,
    selectSubProject: null,
  };
  const { form, documentsToUpload, markDocumentsAsUploaded } = props;
  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const location = useLocation();
  const isAssetDocumentsPath = location.pathname.includes("asset-documents");
  const isSiteDocumentsPath = location.pathname.includes("site-documents");

  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const selectedRowKeysRef = useRef(selectedRowKeys);

  const [equipments, setEquipments] = useState<{
    tagIds: ISelectedItems[];
    packageIds: ISelectedItems[];
  }>({ tagIds: [], packageIds: [] });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [equipmentError, setEquipmentError] = useState<boolean>(false);
  const [documentError, setDocumentError] = useState<boolean>(false);

  const [state, setState] = useState({ ...initialState });
  const dispatch = useDispatch();

  useEffect(() => {
    form.resetFields();
    form.setFieldValue("category", null);
    if (activeMasterProject) {
      onSelectMasterProject("", activeMasterProject);
    }
  }, []);

  const getEquipmentTitle = () =>
    `${
      equipments.tagIds.length || equipments.packageIds.length
        ? "Selected"
        : "Select"
    } ${isAssetDocumentsPath && AlectifyText.ASSETS} ${
      equipments.tagIds.length || equipments.packageIds.length
        ? `(${equipments.tagIds.length + equipments.packageIds.length})`
        : ""
    }`;

  const onEquipmentSelection = (items: any[], newSelectedRowKeys: any[]) => {
    setEquipmentError(false);
    setSelectedRowKeys(newSelectedRowKeys);
    const filterByTag = (items: ISelectedItems[]) => {
      return items?.filter((item: ISelectedItems) => item?.type === "Tag");
    };
    const filterByPackageRoom = (items: ISelectedItems[]) => {
      return items.filter(
        (item: ISelectedItems) => item.type === "PackageRoom",
      );
    };

    const subAssets = filterByTag(items);
    const parentAssets = filterByPackageRoom(items);
    setEquipments({
      tagIds: subAssets,
      packageIds: parentAssets,
    });
  };

  const getEquipmentComponent = () =>
    isAssetDocumentsPath ? (
      <AssetsTable
        showSearch
        rowSelection
        showAssetPackage
        scroll={{ y: "calc(100vh - 360px)" }}
        onRowSelectionAction={onEquipmentSelection}
        subProjectId={state.selectSubProject?.id}
        selectedRowKeys={selectedRowKeys}
      />
    ) : (
      <></>
    );

  useEffect(() => {
    selectedRowKeysRef.current = selectedRowKeys;
  }, [selectedRowKeys]);

  const equipmentModalHandler = () => {
    ModalServiceInstance.open(AlectifyModal, {
      width: 1200,
      style: { top: 20 },
      name: "select-asset-or-package",
      title: getEquipmentTitle(),
      children: getEquipmentComponent(),
      okText: "Submit",
      onOk: () => {
        if (isEmpty(selectedRowKeysRef.current)) {
          message.error(MESSAGES.GENERAL_MESSAGES.NO_ASSET_SELECTED);
          return;
        }
        ModalServiceInstance.close(AlectifyModal, {
          name: "select-asset-or-package",
        });
      },
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: "select-asset-or-package",
        });
      },
    });
  };

  // const showUploadedModalHandler = () =>
  //   ModalServiceInstance.open(AlectifyModal, {
  //     width: 1100,
  //     style: { top: 20 },
  //     name: MODAL_NAMES.SHOW_UPLOADED_DOCUMENTS_MODAL,
  //     title: <>{AlectifyText.SUCCESSFULLY_UPLOADED}</>,
  //     children: <UploadedDocuments />,
  //     footer: (
  //       <AlectifyButton
  //         name="ok"
  //         text="Ok"
  //         type="primary"
  //         onClick={() => {
  //           ModalServiceInstance.close(AlectifyModal, {
  //             name: MODAL_NAMES.SHOW_UPLOADED_DOCUMENTS_MODAL,
  //           });
  //         }}
  //       />
  //     ),
  //     onCancel: () => {
  //       ModalServiceInstance.close(AlectifyModal, {
  //         name: MODAL_NAMES.SHOW_UPLOADED_DOCUMENTS_MODAL,
  //       });
  //     },
  //   });

  const uploadDocumentsHandler = async (dirKey: string) => {
    try {
      const subProjectId = state?.selectSubProject?.id || "";
      const packageIds = equipments.packageIds
        .map(({ package_id }) => package_id)
        .join(",");
      // const params = {
      //   page: PAGINATION.DEFAULT_START_PAGE,
      //   per_page: PAGINATION.DEFAULT_PAGE_SIZE,
      // };

      const formData = new FormData();
      const dirKey = isSiteDocumentsPath ? "Electrical Packages" : "Images";
      const documentTypes = isAssetDocumentsPath
        ? ["TagDocument", "PackageRoomDocument", "ProjectDocuments"]
        : ["ProjectDocuments"];

      formData.append("document_type", documentTypes.join(","));
      formData.append("dir_key", dirKey);
      documentsToUpload.forEach((file) =>
        formData.append("files", file.originFileObj),
      );

      if (isSiteDocumentsPath) {
        // Upload project documents and update the corresponding document list
        formData.append("id", subProjectId);
        await uploadProjectDocuments(subProjectId, formData);
        // dispatch(getProjectDocuments(subProjectId, params));
      } else {
        // Upload asset documents
        if (!isEmpty(equipments.tagIds)) {
          formData.append(
            "tag_ids",
            equipments.tagIds.map(({ tag_id }) => tag_id).join(","),
          );
          await uploadAssetsDocuments(subProjectId, formData);
          // dispatch(getAssetDocuments(subProjectId, params));
        }

        // Upload asset package documents
        if (!isEmpty(equipments.packageIds)) {
          const newFormData = new FormData();
          newFormData.append("dir_key", dirKey);
          documentsToUpload.forEach((file) =>
            newFormData.append("files", file.originFileObj),
          );
          // Omit appending tag_ids for package uploads
          newFormData.append("package_ids", packageIds);
          await uploadAssetPackageDocuments(subProjectId, newFormData);
          // dispatch(getAssetPackageDocuments(subProjectId, params));
        }
      }

      // Fetching documents count
      dispatch(getDocumentsCount(subProjectId));
      // Display a success message and close the modal
      message.success(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_UPLOADED);
      // showUploadedModalHandler();

      const uploadedDocuments = documentsToUpload.flatMap((document) => ({
        ...document,
        dirKey,
        parentAsset: equipments.packageIds[0]?.package_name,
        subAsset: equipments.tagIds[0]?.tag_name,
      }));

      markDocumentsAsUploaded(uploadedDocuments);
      // fetching sub project details to update the count
      // dispatch(getSubProjectDetails(activeMasterProject?.id, subProjectId));
    } catch (error) {
      console.log(error);
    } finally {
      setSelectedRowKeys([]);
    }
  };

  const onUploadDocuments = async (values: IUploadDocumentParams) => {
    if (submitting) {
      return; // Prevent API call if already submitting
    }

    if (isAssetDocumentsPath) {
      if (isEmpty(equipments.tagIds) && isEmpty(equipments.packageIds)) {
        setEquipmentError(true);
        return;
      }
    }
    if (isEmpty(props.selectedDocuments)) {
      setDocumentError(true);
      return;
    }
    setEquipmentError(false);
    setSubmitting(true);
    message.loading(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_UPLOADING);
    await uploadDocumentsHandler(values.dir_key);
    setSubmitting(false);
    form.resetFields();
    setEquipments({ tagIds: [], packageIds: [] });
    if (props.getDocuments) {
      props.getDocuments();
    }

    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.DOCUMENTS_MAIN_DRAWER,
    });
  };

  const onSelectMasterProject = (
    value: string,
    masterProject: IMasterProject,
  ) => {
    form.setFieldValue("masterProject", masterProject.id);
    form.setFieldValue("subProject", null);
    setState({
      ...state,
      selectedMasterProject: masterProject,
      selectSubProject: null,
    });
  };

  const resetUploadLocation = () => {
    form.setFieldValue("category", null);
    form.setFieldValue("dir_key", null);
    setEquipments({ tagIds: [], packageIds: [] });
    setSelectedRowKeys([]);
  };

  const onMasterProjectChange = () => {
    form.setFieldValue("subProject", "");
    setState({
      ...state,
      selectSubProject: null,
    });
    resetUploadLocation();
  };

  const onSelectSubProject = (value: string, subProject: ISubProject) => {
    setState({
      ...state,
      selectSubProject: subProject,
    });
    resetUploadLocation();
  };

  return (
    <Spin spinning={submitting}>
      <Form form={form} layout="vertical" onFinish={onUploadDocuments}>
        <SelectMasterProject
          onSelect={onSelectMasterProject}
          onChange={onMasterProjectChange}
          rules={BASIC_REQUIRED_VALIDATION_RULES}
        />
        <SelectSubProject
          masterProjectId={
            (state.selectedMasterProject && state.selectedMasterProject.id) ||
            ""
          }
          onSelect={onSelectSubProject}
          rules={BASIC_REQUIRED_VALIDATION_RULES}
        />

        {!isEmpty(state.selectSubProject) && isAssetDocumentsPath ? (
          <div className="mb-15">
            <Tooltip
              title={
                isEmpty(equipments.tagIds) &&
                isEmpty(equipments.packageIds) ? null : (
                  <ol className="selected-documents-list">
                    {[...equipments.tagIds, ...equipments.packageIds].map(
                      (equipment: any) => (
                        <li>{equipment?.tag_name || equipment.package_name}</li>
                      ),
                    )}
                  </ol>
                )
              }
            >
              <AlectifyButton
                text={getEquipmentTitle()}
                type="primary"
                htmlType="button"
                className="light-blue-button mt-5"
                onClick={equipmentModalHandler}
                block
              />
            </Tooltip>
            {equipmentError && (
              <Alert
                message={`Please ${getEquipmentTitle()}`}
                type="error"
                className="mt-10"
              />
            )}
          </div>
        ) : (
          <></>
        )}
        <div className="browse-container">
          <Upload
            multiple
            accept="*"
            fileList={[]}
            showUploadList={false}
            beforeUpload={() => false}
            onChange={({ file, fileList }) => {
              setDocumentError(false);
              props.handleFilesUpload({ file, fileList });
            }}
          >
            <AlectifyButton
              type="primary"
              text="Upload Documents"
              className="light-blue-button"
            />
          </Upload>

          {documentError ? (
            <Alert
              message={`Please Upload Documents`}
              type="error"
              className="mt-10"
            />
          ) : (
            <></>
          )}
        </div>

        <AlectifyCard
          title={`Uploaded documents (${
            props.selectedDocuments?.length
              ? props.selectedDocuments?.length
              : 0
          })`}
          className="alectify-add-document-card mt-10"
          extra={
            props.selectedDocuments?.length > 0 ? (
              <AlectifyButton
                type="text"
                danger
                size="small"
                className="danger-button"
                text={AlectifyText.CLEAR_ALL}
                onClick={() => props.setSelectedDocuments([])}
              />
            ) : undefined
          }
        >
          {props.selectedDocuments?.length > 0 ? (
            <ul>
              {props.selectedDocuments.map((document: any) => (
                <li key={document.uid}>
                  <div className=" d-flex justify-content-space-between width-100 align-item-center">
                    <div>
                      <SelectedDocumentIcon />
                      <Tooltip title={document && document?.name}>
                        <Typography.Text>
                          {truncateString(document.name, 35)}
                        </Typography.Text>
                      </Tooltip>
                    </div>
                    <Tooltip title={"Delete"}>
                      <div
                        onClick={() => props.removeSingleDocument(document.uid)}
                      >
                        <CrossRedIcon />
                      </div>
                    </Tooltip>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <>
              <Empty />
            </>
          )}
        </AlectifyCard>
      </Form>
    </Spin>
  );
};

export default UploadDocumentForm;
