import { PROJECT_THEME_COLOR } from "components/shared/phone-number/constants";
import { Svg, Ellipse } from "@react-pdf/renderer";

const AlectifyBulletIconPdf: React.FC = () => {
  return (
    <Svg width="5" height="5" viewBox="0 0 5 5" fill="none">
      <Ellipse cx="2.5" cy="2.5" rx="2.5" ry="2.5" fill={PROJECT_THEME_COLOR} />
    </Svg>
  );
};

export default AlectifyBulletIconPdf;
