import { Button, Space, Tooltip } from "antd";
import { LeftArrowIcon } from "components/icons";
import RightArrowIcon from "components/icons/RightArrowIcon";
import moment from "moment";
import { memo } from "react";
import "./DataRoundsReports.scss";

const DataRoundsCalendarToolbar: React.FC<any> = ({ date, onNavigate }) => {
  const formattedDate = moment(date).format("MMMM YYYY");
  let [monthLabel, yearLabel] = formattedDate.split(" ");

  return (
    <div className="custom-toolbar">
      <Space>
        <span className="toolbar-label mr-10">
          <strong>{monthLabel}</strong>{" "}
          <span className="ml-5">{yearLabel}</span>
        </span>

        <Tooltip title={"Previous month"}>
          <span className="arrow-container " onClick={() => onNavigate("PREV")}>
            <LeftArrowIcon height={"14"} />
          </span>
        </Tooltip>

        <Tooltip title={moment().format("DD MMM YYYY")}>
          <Button onClick={() => onNavigate("TODAY")}>
            <span className="font-size-12">Today</span>
          </Button>
        </Tooltip>

        <Tooltip title={"Next month"}>
          <span className="arrow-container" onClick={() => onNavigate("NEXT")}>
            <RightArrowIcon height={"14"} />
          </span>
        </Tooltip>
      </Space>
    </div>
  );
};

export default memo(DataRoundsCalendarToolbar);
