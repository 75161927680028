import { Space, Tag } from "antd";
import React, { ReactElement, ReactNode } from "react";
import "./AlectifyTags.scss";

const IncidentTypeTag: React.FC<{
  text?: string | ReactElement | ReactNode;
  icon?: ReactElement | ReactNode;
  color?: string;
  borderColor?: string;
}> = ({ text, icon, color, borderColor }) => {
  const renderText = (text: string | ReactElement | ReactNode) => {
    if (typeof text === "string") {
      return text;
    } else if (React.isValidElement(text)) {
      return text;
    } else {
      return JSON.stringify(text);
    }
  };

  return (
    <Tag
      color={color}
      className="incident-tag round-tag"
      style={{ border: `1px solid ${borderColor}` }}
    >
      <Space size={2}>
        {renderText(text || "-")} {icon && icon}
      </Space>
    </Tag>
  );
};

export default IncidentTypeTag;
