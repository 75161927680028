/**
 * Process sourceDocuments based on the provided response object.
 * @param {any} response - The response object containing sourceDocuments and answer.
 * @returns {any[]} - An array containing processed unique sources based on the conditions.
 */
export const processSources = (response: any): any[] => {
  // Filter out unique sources based on the page_number property
  const uniqueSources = response?.data.sourceDocuments?.filter(
    (source: any, index: number) =>
      index ===
      response?.data?.sourceDocuments.findIndex(
        (s: any) => s?.metadata.page_number === source?.metadata.page_number,
      ),
  );

  // If the answer starts with "I don't know the answer",
  // return only the first unique source object, else return all unique sources
  return response?.answer?.startsWith("I don't know the answer")
    ? [uniqueSources[0]]
    : uniqueSources;
};
