import React, { ReactNode, useState, useEffect } from "react";
import { Col, Row, Skeleton, Tag, Timeline } from "antd";
import dayjs from "dayjs";
import { IDataRoundShiftTimelineProps } from "./DataRoundShiftTimeline.interface";
import "./DataRoundTimeline.scss";
import { CircleDotIcon } from "components/icons";
import ShiftCards from "./ShiftCards";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { DataRoundFrequncyEnum } from "pages/data-round/create-edit/DrProjectCreation.interface";

const DataRoundShiftTimeline: React.FC<IDataRoundShiftTimelineProps> = (
  props: IDataRoundShiftTimelineProps,
) => {
  const [visibleItems, setVisibleItems] = useState<number>(5);
  const [timelineItems, setTimelineItems] = useState<any[]>([]);

  const generateShiftCards = (date: string): ReactNode => {
    if (!isEmpty(props.project)) {
      return (
        <>
          {props.project?.shift_schedules.map((schedule, i) => (
            <ShiftCards
              key={i}
              insights={props.insights}
              shiftSchedule={schedule}
              shiftDate={date}
            />
          ))}
        </>
      );
    }
    return null;
  };

  const startDate = dayjs(props.dateRange.start_date);
  const endDate = dayjs(props.dateRange.end_date);

  // Generate all timeline items when the component mounts or the date range changes
  useEffect(() => {
    const items: any[] = [];
    for (
      let currentDate = startDate;
      currentDate.isBefore(endDate) || currentDate.isSame(endDate);
      currentDate = currentDate.add(1, "day")
    ) {
      const dayOfWeek = (currentDate.day() + 6) % 7; // Adjust day index to match backend values
      const shiftCards = generateShiftCards(currentDate.format("YYYY-MM-DD"));

      // Filter dates based on days_of_week for weekly frequency
      if (
        props.project?.frequency_type === DataRoundFrequncyEnum.WEEKLY &&
        !props.project?.shift_schedules.some(
          (schedule) => schedule.days_of_week?.includes(dayOfWeek),
        )
      ) {
        continue;
      }

      if (shiftCards) {
        items.push({
          label: (
            <Tag
              color={"#fff"}
              className="round-tag alectify-timeline-date-tag"
            >
              {currentDate.format("MMM DD, YYYY")}
            </Tag>
          ),
          key: currentDate.format("YYYY-MM-DD"),
          dot: <CircleDotIcon />,
          children: shiftCards,
        });
      }
    }
    setTimelineItems(items.reverse());
  }, [props.dateRange, props.project, props.insights]);

  const loadMoreItems = () => {
    setTimeout(() => {
      setVisibleItems((prev) => Math.min(prev + 5, timelineItems.length));
    }, 200);
  };

  return (
    <Row justify={"start"} align={"middle"}>
      {props.loader ? (
        <Skeleton />
      ) : (
        <Col span={24} className="round-timeline-container" id="scrollableDiv">
          <InfiniteScroll
            height={"calc(100vh - 60vh)"}
            dataLength={visibleItems}
            next={loadMoreItems}
            hasMore={visibleItems < timelineItems.length}
            loader={<Skeleton active />}
            endMessage={<p style={{ textAlign: "center" }}>End of timeline</p>}
            scrollableTarget="scrollableDiv"
          >
            <Timeline mode="left" className="round-timeline">
              {timelineItems.slice(0, visibleItems).map((item) => (
                <Timeline.Item key={item.key} label={item.label} dot={item.dot}>
                  {item.children}
                </Timeline.Item>
              ))}
            </Timeline>
          </InfiniteScroll>
        </Col>
      )}
    </Row>
  );
};

export default DataRoundShiftTimeline;
