import { useEffect, useRef, useState } from "react";
import { PAGINATION } from "constants/index";
import DocumentsTable from "components/shared/add-document/documents-table";
import {
  IPagination,
  ITableFilterType,
} from "components/shared/table/AlectifyTable.interface";
import { fetchDocuments } from "services/documents/documents.service";
import { IRootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import { Breadcrumb, Typography } from "antd";
import AlectifyText from "static/texts.json";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";
import { isEmpty } from "lodash";

const Documents: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAssetDocumentsPath = location.pathname.includes("asset-documents");

  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const activeMasterProjectRef = useRef(activeMasterProject);

  const [document, setDocument] = useState({
    data: [],
    meta: null,
    fetching: false,
  });
  const [optionsPersisted, setPersistedOptions] = useState<IPagination | null>(
    null,
  );

  const getDocuments = async (options?: IPagination | null) => {
    setDocument({ ...document, fetching: true });
    const params = {
      master_project: activeMasterProjectRef.current?.id || null,
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      is_active:
        options?.is_active === "all" ? undefined : options?.is_active ?? true,

      document_type: isAssetDocumentsPath
        ? "TagDocument,PackageRoomDocument"
        : "ProjectDocuments",
      ...options,
    };

    if (!isEmpty(options)) setPersistedOptions(options);

    const response = await fetchDocuments(params);

    setDocument({
      ...document,
      fetching: false,
      data: response.data,
      meta: response.meta,
    });

    try {
    } catch (err) {
      console.log(err);
      setDocument({ ...document, fetching: false });
    }
  };

  useEffect(() => {
    activeMasterProjectRef.current = activeMasterProject;
    getDocuments({ ...optionsPersisted, is_active: "true" } as any);
  }, [activeMasterProject, isAssetDocumentsPath]);

  return (
    <div className="pR-10">
      <Breadcrumb
        className=""
        items={[
          {
            title: "Home",
            href: `#`,
            onClick: () => {
              navigate(ROUTES.PROJECT);
            },
          },
          {
            title: isAssetDocumentsPath
              ? AlectifyText.ASSET_DOCUMENTS
              : AlectifyText.SITE_DOCUMENTS,
          },
        ]}
      />

      <Typography.Title level={4} className="mt-10">
        {isAssetDocumentsPath
          ? AlectifyText.ASSET_DOCUMENTS
          : AlectifyText.SITE_DOCUMENTS}
      </Typography.Title>
      <DocumentsTable
        document={document}
        getDocuments={getDocuments}
        filterType={ITableFilterType.DROPDOWN}
        optionsPersisted={optionsPersisted}
        filters={
          {
            key: "is_active",
            defaultValue: "true",
            options: [
              { label: "All", value: "all" },
              { label: "Active", value: "true" },
              { label: "Inactive", value: "false" },
            ],
          } as any
        }
      />
    </div>
  );
};

export default Documents;
