import { Col, Radio, Row, Space, Typography } from "antd";
import { IConfirmationProps } from "./Confirmation.interface";
import AlectifyButton from "../button";
import { useState } from "react";
import { MESSAGES } from "constants/messages";

const ConfirmationModal: React.FC<IConfirmationProps> = (
  props: IConfirmationProps,
) => {
  const [selectedOption, setSelectedOption] = useState<number>(
    props.assetCategoryDetail?.is_draft ? 1 : 2,
  );

  const handleOptionChange = (e: any) => {
    setSelectedOption(parseInt(e.target.value));
  };

  return (
    <Row justify={"center"} align={"middle"}>
      {props.icon && (
        <Col span={24} className="text-align-center">
          <props.icon />
        </Col>
      )}
      <Col span={24} className="text-align-center">
        <Typography.Text className="mb-0">{props.message}</Typography.Text>
      </Col>
      <Col span={24} className="text-align-center">
        {props.extraInformation}
      </Col>
      {props.note && (
        <Col span={24} className="text-align-center">
          <Typography.Text type="secondary">{props.note}</Typography.Text>
        </Col>
      )}
      {props.options && (
        <Radio.Group onChange={handleOptionChange} value={selectedOption}>
          <Space direction="vertical" className="mb-20 mt-20">
            {props.assetCategoryDetail?.is_draft && (
              <Radio value={1}>{MESSAGES.CONFIRMATION.OPTION_1} </Radio>
            )}
            <Radio value={2}>{MESSAGES.CONFIRMATION.OPTION_2} </Radio>
          </Space>
        </Radio.Group>
      )}
      {props.showConfirmationButtons && (
        <Col span={24} className="text-align-center mt-10">
          <AlectifyButton
            name="No"
            text={props.cancelText || "No"}
            type="default"
            className="alectify-default-button"
            onClick={props.onCancel && props.onCancel}
          />
          <AlectifyButton
            name="Yes"
            text={props.okText || "Yes"}
            type="primary"
            className="ml-10"
            onClick={() => props.onConfirm && props.onConfirm(selectedOption)}
          />
        </Col>
      )}
    </Row>
  );
};

ConfirmationModal.defaultProps = {
  showConfirmationButtons: true,
  onCancel: () => {},
  onConfirm: () => {},
};
export default ConfirmationModal;
