import { message, Spin } from "antd";
import { MESSAGES } from "constants/messages";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { getSignedUrl } from "services/documents/documents.service";
import { IAlectifyVideoPlayerProps } from "../video-player/AlectifyVideoPlayer.interface";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./style.scss";

const AlectifyAudioPlayer: React.FC<IAlectifyVideoPlayerProps> = ({
  filePath,
  isSigned = false,
}: IAlectifyVideoPlayerProps) => {
  const [loader, setLoader] = useState(false);
  const [signedUrl, setSignedUrl] = useState("");
  const audioPlayerRef = useRef<AudioPlayer>(null);

  const fetchSignedUrl = useCallback(async () => {
    if (!filePath) {
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
      return;
    }

    setLoader(true);
    try {
      const response = await getSignedUrl(filePath);
      if (response.success) {
        setSignedUrl(response.data || "");
      } else {
        message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      console.error("Error fetching signed URL:", error);
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
    } finally {
      setLoader(false);
    }
  }, [filePath]);

  useEffect(() => {
    if (!isSigned) {
      fetchSignedUrl();
    }
  }, [fetchSignedUrl, isSigned]);

  const audioSource = isSigned ? filePath : signedUrl;

  return (
    <div className="audio-player">
      <Spin spinning={loader}>
        {!isEmpty(audioSource) && (
          <div className="mt-10">
            <AudioPlayer
              ref={audioPlayerRef}
              autoPlay={false}
              src={audioSource}
              showJumpControls={false}
              autoPlayAfterSrcChange={false}
              customAdditionalControls={[]}
              customVolumeControls={[]}
              layout="horizontal-reverse"
              onPlay={() => {}}
              onCanPlay={() => {}}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default AlectifyAudioPlayer;
