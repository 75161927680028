import React, { useEffect, useState } from "react";
import "./DataRoundsReports.scss";
import {
  IDataRoundsInfoModalProps,
  IRoundsInfo,
  IShiftProps,
  IStateInfo,
} from "./DataRoundsReports.interface";
import { getDataRoundProjectInsights } from "services/data-round/data-round.service";
import AlectifyTable from "../table";
import AlectifyText from "static/texts.json";
import { Avatar, Space, Tooltip } from "antd";
import { getFullUserName } from "utils/helpers";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { NO_IMAGE_AVATAR } from "../assets-table/AssetsTable.constants";
import RoundsDetailTable from "../rounds-detail-table/RoundsDetailTable";
import { isEmpty } from "lodash";

const DataRoundsInfoModal: React.FC<IDataRoundsInfoModalProps> = (props) => {
  const [state, setState] = useState<IStateInfo>({
    data: [],
    fetching: false,
  });

  useEffect(() => {
    fetchRounds();
  }, [props.projectId]);

  const fetchRounds = async () => {
    try {
      if (props.projectId) {
        setState((prev: IStateInfo) => ({
          ...prev,
          fetching: true,
        }));
        const response = await getDataRoundProjectInsights(props.projectId, {
          start_date: props.event.shift_date,
          end_date: props.event.shift_date,
        });
        setState((prev: any) => ({
          ...prev,
          data: response.data,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setState((prev: IStateInfo) => ({
        ...prev,
        fetching: false,
      }));
    }
  };

  const columns = [
    {
      title: AlectifyText.SHIFT,
      width: 150,
      ellipsis: true,
      key: "shift",
      dataIndex: "shift",
      render: (record: IShiftProps, values: IRoundsInfo) => (
        <div>{values?.shift?.name || "-"}</div>
      ),
    },

    {
      title: AlectifyText.ISSUES,
      width: 80,
      key: "total_issues_count",
      dataIndex: "total_issues_count",
    },
    {
      title: AlectifyText.COMMENTS,
      width: 80,
      key: "total_comments_count",
      dataIndex: "total_comments_count",
    },
    {
      title: AlectifyText.DOCUMENTS,
      width: 80,
      key: "total_docs_count",
      dataIndex: "total_docs_count",
    },

    {
      title: AlectifyText.USER,
      width: 180,
      key: "user",
      dataIndex: "user",
      render: (user: any, record: IRoundsInfo) => {
        return (
          <>
            {(!isEmpty(user) && (
              <Space>
                <Tooltip title={`${getFullUserName(user)}`}>
                  <Avatar
                    src={user?.image}
                    className="cursor-pointer"
                    onClick={() =>
                      ModalServiceInstance.open(AlectifyModal, {
                        name: "alectify-task-image-viewer",
                        title: "Preview",
                        footer: null,
                        onCancel: () => {
                          ModalServiceInstance.close(AlectifyModal, {
                            name: "alectify-task-image-viewer",
                          });
                        },
                        children: (
                          <img
                            src={user?.image || NO_IMAGE_AVATAR}
                            alt="User Avatar"
                            style={{ width: "100%", height: "auto" }}
                          />
                        ),
                      })
                    }
                  />
                </Tooltip>
                <span className="creator-name-date">
                  {getFullUserName(user)}
                </span>
              </Space>
            )) || <span className="red-text">Did Not Finish</span>}
          </>
        );
      },
    },
  ];

  return (
    <div className="data-rounds-info-modal">
      <AlectifyTable
        expandable={{
          expandedRowRender: (record: any) => {
            return (
              <div>
                <RoundsDetailTable
                  projectId={props.projectId}
                  roundId={record.id}
                />
              </div>
            );
          },
        }}
        loading={state.fetching}
        columns={columns}
        dataSource={state.data}
        onDataFetch={fetchRounds}
        total={state.data.length}
        showPagination={false}
        scroll={{ y: "calc(100vh - 360px)" }}
      />
    </div>
  );
};

export default DataRoundsInfoModal;
