import React from "react";
import AlectifyText from "static/texts.json";
import { Avatar, Radio, Space, Tooltip, Typography } from "antd";
import { EditIconFilledIcon, UserIcon } from "components/icons";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { CloseOutlined } from "@ant-design/icons";
import { IProcedure } from "services/procedures/procedures.interface";
import { getProceduresTag } from "../Procedures.helpers";
import { displayDateTime, getFullUserName } from "utils/helpers";
import "../procedures-listing/procedures-card/ProceduresCard.scss";
import { isEmpty, truncate } from "lodash";
import StepsTag from "components/shared/tags/StepsTag";
import ProcedureDetail from "components/shared/procedure-detail";
import ProcedureCreateEdit from "../procedure-creation";
import {
  CustomColumnProps,
  IProcedureColumnProps,
} from "../procedures-listing/ProceduresListing.interface";
import { AlectifyDetailCardImage } from "components/shared/detail-card";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import { FilterOutlined } from "@ant-design/icons";

const useProcedureColumn = ({ FormInstance }: IProcedureColumnProps) => {
  const openDetailDrawer = (record: IProcedure) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
      title: truncate(record.name, { length: 20, omission: "..." }),
      width: 677,
      showFooter: true,
      cancelText: AlectifyText.CLOSE,
      readOnly: true,
      destroyOnClose: true,
      closeIcon: <CloseOutlined />,
      closable: true,
      onClose: () =>
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
        }),
      children: (
        <ProcedureDetail procedure={record} readonly isDisabled={false} />
      ),
    });
  };

  const openCreationDrawer = (isEdit = false, procedure?: IProcedure) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.DRAWER_PROCEDURE_CREATION,
      width: 1471,
      title: isEdit
        ? AlectifyText.UPDATE_PROCEDURE
        : AlectifyText.ADD_NEW_PROCEDURE,
      showFooter: false,
      closeIcon: <CloseOutlined />,
      closable: true,
      onClose: () =>
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.DRAWER_PROCEDURE_CREATION,
        }),
      children: (
        <ProcedureCreateEdit
          FormInstance={FormInstance}
          isEdit={isEdit}
          procedure={procedure || null}
          searchText={""}
        />
      ),
    });
  };

  const columns: CustomColumnProps<IProcedure>[] | any[] = [
    {
      title: AlectifyText.REF,
      dataIndex: "reference",
      key: "reference",
      visible: true,
      width: 40,
      sorter: true,
      sortingColumn: "reference",
      ellipsis: { showTitle: false },
      onCell: (record) => ({
        style: { cursor: "pointer" },
        onClick: () => openDetailDrawer(record),
      }),
      render: (reference: string) => (
        <Tooltip placement="topLeft" title={reference}>
          {reference || "-"}
        </Tooltip>
      ),
    },
    {
      title: AlectifyText.PROCEDURE_NAME,
      searchable: true,
      dataIndex: "name",
      visible: true,
      width: 200,
      onCell: (record) => ({
        style: { cursor: "pointer" },
        onClick: () => openDetailDrawer(record),
      }),
      render: (name: string, record: IProcedure) => (
        <Tooltip placement="topLeft" title={name}>
          <div className="d-flex align-items-center">
            <div className="width-50">
              <AlectifyDetailCardImage
                src={record?.imageUrl || NO_IMAGE_AVATAR}
                className="procedure-card-table-img"
                showImage
              />
            </div>
            <Typography.Text className="procedure-name-text" ellipsis>
              {name}
            </Typography.Text>
          </div>
        </Tooltip>
      ),
    },
    {
      title: AlectifyText.JOB_TYPE,
      dataIndex: "jobType",
      visible: true,
      width: 100,
      filters: [
        { text: "Job Plan", value: "Job Plan" },
        {
          text: "Standard Operating Procedure",
          value: "Standard Operating Procedure",
        },
        { text: "Method of Procedure", value: "Method of Procedure" },
      ],
      filterIcon: <FilterOutlined />,
      onFilter: (value: string, record: IProcedure) =>
        record.jobType.includes(value),
      onCell: (record) => ({
        style: { cursor: "pointer" },
        onClick: () => openDetailDrawer(record),
      }),
      render: (jobType: any) => {
        const Tag = getProceduresTag(jobType);
        return <Tag />;
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Radio.Group
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys([e.target.value]);
              confirm();
            }}
          >
            <Radio
              style={{ display: "block", marginBottom: 4 }}
              value="Job Plan"
            >
              Job Plan
            </Radio>
            <Radio
              style={{ display: "block", marginBottom: 4 }}
              value="Standard Operating Procedure"
            >
              Standard Operating Procedure
            </Radio>
            <Radio style={{ display: "block" }} value="Method of Procedure">
              Method of Procedure
            </Radio>
          </Radio.Group>
          {selectedKeys.length > 0 && (
            <div
              style={{
                marginTop: 8,
                textAlign: "center",
                color: "blue",
                cursor: "pointer",
              }}
              onClick={() => {
                clearFilters?.();
                confirm?.();
              }}
            >
              Clear Filter
            </div>
          )}
        </div>
      ),
    },

    {
      title: AlectifyText.STEPS,
      dataIndex: "step",
      visible: true,
      width: 40,
      ellipsis: { showTitle: false },
      onCell: (record) => ({
        style: { cursor: "pointer" },
        onClick: () => openDetailDrawer(record),
      }),
      render: (_: any, record: IProcedure) => (
        <StepsTag count={record?.procedureSteps?.length || 0} />
      ),
    },
    {
      title: AlectifyText.CREATED_BY,
      dataIndex: "createdBy",
      visible: true,
      width: 80,
      ellipsis: { showTitle: false },
      onCell: (record) => ({
        style: { cursor: "pointer" },
        onClick: () => openDetailDrawer(record),
      }),
      render: (_: any, record: IProcedure) => {
        const imageUrl = record?.createdBy?.image_url;
        const userName = record?.createdBy
          ? getFullUserName(record.createdBy)
          : "-";

        return (
          <Space className="creator-info">
            {isEmpty(imageUrl) ? (
              <UserIcon />
            ) : (
              <Avatar src={imageUrl} size="small" />
            )}
            <Space>
              <span className="creator-name-date">{userName}</span>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdBy",
      visible: true,
      width: 50,
      ellipsis: { showTitle: false },
      onCell: (record) => ({
        style: { cursor: "pointer" },
        onClick: () => openDetailDrawer(record),
      }),
      render: (_: any, record: IProcedure) => {
        return (
          <Space className="creator-info">
            <Space>{displayDateTime(record.createdAt, false)}</Space>
          </Space>
        );
      },
    },
    {
      title: AlectifyText.ACTIONS,
      visible: true,
      width: 30,
      ellipsis: { showTitle: false },
      onCell: (record) => ({
        style: { cursor: "pointer" },
        onClick: () => openCreationDrawer(true, record),
      }),
      render: () => (
        <Tooltip title={AlectifyText.EDIT} className="cursor-pointer">
          <EditIconFilledIcon />
        </Tooltip>
      ),
    },
  ];

  return React.useMemo(
    () => columns.filter((col) => col.visible !== false),
    [columns],
  );
};

export default useProcedureColumn;
