import { getRoundDetails } from "services/data-round/data-round.service";
import { IRoundsDetailTableProps } from "./RoundsDetailTable.interface";
import { useEffect, useState } from "react";
import { IRound } from "services/data-round/data-round.interface";
import useRoundDetails from "./effects/useRoundDetailTable";
import AlectifyTable from "../table";

const RoundsDetailTable: React.FC<IRoundsDetailTableProps> = (
  props: IRoundsDetailTableProps,
) => {
  const [round, setRound] = useState<IRound[] | null>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const fetchRoundDetails = async () => {
    setLoader(true);
    try {
      const response = await getRoundDetails(props.projectId, props.roundId);
      setLoader(false);
      if (response.success) {
        setRound((response.data && response.data) || null);
      }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  const columns = useRoundDetails();
  useEffect(() => {
    fetchRoundDetails();
  }, [props.projectId, props.roundId]);

  return (
    <AlectifyTable
      dataSource={round || []}
      columns={columns}
      onDataFetch={fetchRoundDetails}
      total={round?.length || 0}
      scroll={{ y: 410 }}
      loading={loader}
    />
  );
};

export default RoundsDetailTable;
