import React, { memo } from "react";
import { Skeleton } from "antd";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { ICommentListProps } from "./Comments.interface";
import CommentItem from "./CommentItem";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Comment.scss";

const CommentList: React.FC<ICommentListProps> = ({ getParams, params }) => {
  const state = useSelector((state: IRootState) => state.common);

  return (
    <div
      id="scrollableDiv"
      style={{
        height: "calc(100vh - 220px)",
        overflow: "auto",
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      <InfiniteScroll
        height={"calc(100vh - 220px)"}
        dataLength={state.comments.data.length}
        next={() => {
          getParams && getParams(params?.page + 1);
        }}
        style={{ display: "flex", flexDirection: "column-reverse" }}
        inverse={true}
        hasMore={
          state.comments.data?.length < (state.comments.meta?.total_count || 0)
        }
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        scrollableTarget="scrollableDiv"
      >
        {state?.comments?.data?.map((comment) => (
          <CommentItem key={comment.id} comment={comment} />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default memo(CommentList);
