import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spin, message } from "antd";
import AlectifyInput from "components/shared/input";
import OrganizationsAutoComplete from "components/shared/autocomplete/OrganizationsAutoComplete";
import SearchAddress from "components/shared/search-address";
import {
  createMasterProjects,
  fetchMasterProjectDetails,
  updateMasterProjectDetails,
} from "services/master-project/masterProject.service";
import { IRootState } from "redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import {
  IGoogleMapsAPI,
  IMasterProjectItem,
} from "components/shared/map/Alectify.interface";
import AlectifyMap from "components/shared/map";
import AlectifyText from "static/texts.json";
import "./MasterContent.scss";
import { IMapAddress, IMasterContentProps } from "./ProjectMap.interface";
import { setActiveMasterProject } from "redux/components/common/sources/index";
import { getMasterProject } from "redux/components/master-project/sources";
import { get } from "lodash";
import { IMasterProjectPayload } from "services/master-project/masterProject.interface";
import { removeEmptyValues } from "utils/helpers";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { MESSAGES } from "constants/messages";

const MasterProjectCreationForm: React.FC<IMasterContentProps> = ({
  FormInstance,
  existingMasterProject,
}) => {
  const { user } = useSelector((state: IRootState) => state.auth);
  const dispatch = useDispatch();
  const [currentMasterProject, setCurrentMasterProject] =
    useState<IMasterProjectItem>({
      address: existingMasterProject?.address || "",
      latitude: existingMasterProject?.latitude || 0,
      longitude: existingMasterProject?.longitude || 0,
      center: [
        existingMasterProject?.latitude || 0,
        existingMasterProject?.longitude || 0,
      ],
    });

  const [loader, setLoader] = useState<boolean>(false);
  const [mapaddress, setMapaddress] = useState<IMapAddress>({
    map: null,
    mapApi: null,
    apiReady: false,
  });

  useEffect(() => {
    if (existingMasterProject) {
      FormInstance.setFieldsValue({
        ...existingMasterProject,
        organization_name: existingMasterProject.owner || "",
      });
      setCurrentMasterProject({
        ...currentMasterProject,
        latitude: existingMasterProject.latitude,
        longitude: existingMasterProject.longitude,
      });
    } else {
      setCurrentLocation();
    }
  }, []);

  const setCurrentLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentMasterProject((prev: any) => ({
          ...prev,
          center: [position.coords.latitude, position.coords.longitude],
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }));
      });
    }
  };

  const getProjectDetails = async (projectId: string) => {
    const body = { master_project_id: projectId };
    const response = await fetchMasterProjectDetails(body);
    dispatch(setActiveMasterProject(get(response, "data[0]")));
  };

  const onSubmit = async (values: any) => {
    setLoader(true);
    let body: IMasterProjectPayload = {
      name: values?.name || "",
      owner: values?.organization_name || "",
      branch: user?.branch || null,
      address: values?.address || null,
      description: values?.description || null,
      latitude: currentMasterProject?.latitude,
      longitude: currentMasterProject?.longitude,
      work_id_prefix: values?.work_id_prefix || null,
      color: values?.color || null,
      site_code: values.site_code || "",
    };

    body = removeEmptyValues(body);

    if (existingMasterProject) {
      const response = await updateMasterProjectDetails(
        existingMasterProject?.id,
        body,
      );
      if (response.data) {
        message.success(`${values?.name} Project Updated Successfully`);
        FormInstance.resetFields();
        getProjectDetails(existingMasterProject?.id);
        dispatch(getMasterProject({ version: 2 }));
        dispatch(setActiveMasterProject(response.data));
        setLoader(false);
      }
      DrawerServiceInstance.close(AlectifyDrawer, {
        name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
      });
    } else {
      await FormInstance.validateFields();
      const response = await createMasterProjects(body);
      if (response.data) {
        message.success(`${values?.name} ${MESSAGES.SITE.SITE_SUCCESS}`);
        FormInstance.resetFields();
        dispatch(getMasterProject({ version: 2 }));
        setLoader(false);
      }
      DrawerServiceInstance.close(AlectifyDrawer, {
        name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
      });
    }
    setLoader(false);
  };

  const handleApiLoaded = (map: any, maps: any) => {
    if (map && maps) {
      setMapaddress({ ...mapaddress, apiReady: true, map, mapApi: maps });
    }
  };

  const generateAddress = (_lat: number, _lng: number) => {
    if (mapaddress.mapApi) {
      const geocoder = new (mapaddress?.mapApi as IGoogleMapsAPI).Geocoder();
      geocoder.geocode(
        { location: { lat: _lat, lng: _lng } },
        (results: any, status: any) => {
          if (status === "OK") {
            if (results[0]) {
              setCurrentMasterProject((prev: any) => ({
                ...prev,
                address: results[0].formatted_address,
              }));
              FormInstance.setFieldsValue({
                address: results[0].formatted_address,
              });
            } else {
              window.alert("No results found");
            }
          } else {
            window.alert("Geocoder failed due to: " + status);
          }
        },
      );
    } else {
      console.log("mapaddress.mapApi is not initialized");
    }
  };

  const addPlace = (place: any) => {
    setCurrentMasterProject((prev: any) => ({
      ...prev,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      address: place.formatted_address,
    }));
    FormInstance.setFieldsValue({
      address: place.formatted_address,
    });
  };

  const updateLocation = (item: any) => {
    setCurrentMasterProject((prev) => ({
      ...prev,
      latitude: item.lat,
      longitude: item.lng,
    }));
    generateAddress(item.lat, item.lng);
  };

  return (
    <div className="master-content-container">
      <Spin spinning={loader}>
        <Form
          name="basic"
          layout="vertical"
          onFinish={onSubmit}
          validateTrigger="onSubmit"
          form={FormInstance}
          initialValues={{
            name: existingMasterProject?.name || "",
            description: existingMasterProject?.description || "",
            organization_name: existingMasterProject?.owner || "",
            color: existingMasterProject?.color || "",
            work_id_prefix: existingMasterProject?.work_id_prefix || "",
          }}
        >
          <AlectifyInput
            label={AlectifyText.SITE_NAME}
            name="name"
            type="text"
            rules={[
              {
                required: true,
                message: MESSAGES.SITE.PLEASE_SELECT_SITE_NAME,
              },
            ]}
            placeholder={MESSAGES.SITE.PLEASE_SELECT_SITE_NAME}
          />

          <Form.Item
            label={AlectifyText.DESCRIPTION}
            name="description"
            rules={[
              {
                max: 255,
                message: "Description cannot exceed 255 characters",
              },
            ]}
          >
            <AlectifyInput
              name="description"
              type="textarea"
              placeholder="Enter Description"
              max={255}
            />
          </Form.Item>

          <OrganizationsAutoComplete
            name="organization_name"
            placeholder="Search Owner Organization"
            label={AlectifyText.OWNER_ORGANIZATION}
            required
          />
          <Row gutter={16}>
            <Col span={12}>
              <AlectifyInput
                label={AlectifyText.WORK_ID_PREFIX}
                name="work_id_prefix"
                type="text"
                placeholder="Enter Work ID Prefix"
              />
            </Col>
            <Col span={12}>
              <AlectifyInput
                label="Color"
                name="color"
                type="color"
                placeholder="Enter Color"
                className="master-color-input"
              />
            </Col>
          </Row>
          <Col span={24}>
            <AlectifyInput
              label="Site Code"
              name="site_code"
              type="text"
              placeholder="Enter Site Code"
            />
          </Col>
          <Form.Item label={AlectifyText.SITE_ADDRESS} name="address">
            <SearchAddress
              addplace={addPlace}
              map={mapaddress.map}
              mapApi={mapaddress.mapApi}
            />
          </Form.Item>

          <div className="map-container mb-30">
            <AlectifyMap
              onChange={() => {}}
              updateLocation={updateLocation}
              handleApiLoaded={handleApiLoaded}
              masterprojectItem={currentMasterProject}
            />
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default MasterProjectCreationForm;
