import { IRootState } from "redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AssetsIcon from "components/icons/AssetsIcon";
import PmExternalIcon from "components/icons/PmExternalIcon";
// import PmInternalIcon from "components/icons/PmInternalIcon";
import TasksIcon from "components/icons/TasksIcon";
import AlectifyText from "static/texts.json";
import Tasks from "./tasks";
import Assets from "./assets";
// import PmInternal from "./pm-internal";
import PmExternal from "./pm-external";
import "./SubProjectDetailTabs.scss";
import { memo, useEffect } from "react";
import { find, isEmpty } from "lodash";
import { setActiveSubProject } from "redux/components/common/sources";
import { fetchSubProjects } from "services/sub-project/sub-project.service";
import { isFeatureEnabled, sumValues } from "utils/helpers";
import { FEATURE_FLAGS } from "redux/components/Auth";
import { getPmsCounts } from "redux/components/pm-internal/sources";
import { setTableFilters } from "redux/components/table-filters/sources";

interface TabItem {
  key: string;
  label: React.ReactNode;
  children: React.ReactNode;
  visible: boolean; // New property
}

const SubProjectDetailTabs: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { tasks, pmExternal } = useSelector((state: IRootState) => state);

  const activeTab =
    new URLSearchParams(location.search).get("page") || "assets";

  const { masterProjectId } = useParams();
  const { activeSubProject, activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const subProject = useSelector(({ subProject }: IRootState) => subProject);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPmsCounts(activeMasterProject?.id, activeSubProject?.id, {}));
  }, [activeSubProject?.id]);

  // useEffect(() => {
  // if (isEmpty(activeMasterProject))
  //   navigate(`${ROUTES.PROJECT}`);
  // if (
  //   !isEmpty(previousActiveMasterProject) &&
  //   activeMasterProject?.id !== previousActiveMasterProject?.id
  // ) {
  //   navigate(`${ROUTES.PROJECT}/${activeMasterProject?.id}`);
  //   dispatch(setActiveMasterProject(activeMasterProject));
  // }
  // }, [activeMasterProject, previousActiveMasterProject]);

  // const documentCounts =
  //   (activeSubProject?.asset_docs_count || 0) +
  //   (activeSubProject?.package_docs_count || 0) +
  //   (activeSubProject?.project_docs_count || 0);

  const items: TabItem[] = [
    {
      key: "assets",
      label: (
        <>
          <AssetsIcon /> {AlectifyText.ASSETS} (
          {(activeSubProject?.tags_count || 0) +
            (activeSubProject?.pkg_count || 0)}
          )
        </>
      ),
      children: <Assets />,
      visible: true,
    },
    // {
    //   key: "asset-packages",
    //   label: (
    //     <>
    //       <AssetPackagesIcon /> {AlectifyText.PARENT_ASSET_NAME} (
    //       {activeSubProject?.pkg_count || 0})
    //     </>
    //   ),
    //   children: <AssetPackages />,
    //   visible: true,
    // },
    {
      key: "pm-external",
      label: (
        <>
          <PmExternalIcon /> {AlectifyText.PM_EXTERNAL} (
          {sumValues([
            pmExternal.pendingCounts,
            pmExternal.completedCounts,
            pmExternal.skippedCounts,
          ])}
          )
        </>
      ),
      children: <PmExternal isMasterPM={false} />,
      visible: isFeatureEnabled(FEATURE_FLAGS.PM_EXTERNAL),
    },
    {
      key: "tasks",
      label: (
        <>
          <TasksIcon /> {AlectifyText.TASKS} (
          {sumValues([
            tasks.pendingCounts,
            tasks.completedCounts,
            tasks.skippedCounts,
          ])}
          )
        </>
      ),
      children: <Tasks isMasterPM={false} />,
      visible: isFeatureEnabled(FEATURE_FLAGS.TASK),
    },
    // {
    //   key: "pm-internal",
    //   label: (
    //     <>
    //       <PmInternalIcon /> {AlectifyText.PM_INTERNAL} (
    //       {sumValues([
    //         pmInternal.pendingCounts,
    //         pmInternal.completedCounts,
    //         pmInternal.skippedCounts,
    //       ])}
    //       )
    //     </>
    //   ),
    //   children: <PmInternal />,
    //   visible: isFeatureEnabled(FEATURE_FLAGS.PM_INTERNAL),
    // },
    // {
    //   key: "documents",
    //   label: (
    //     <>
    //       <DocumentsIcon /> {AlectifyText.DOCUMENTS} ({documentCounts || 0})
    //     </>
    //   ),
    //   children: <Documents />,
    //   visible: true,
    // },
  ];

  const initializeTabsComponent = async () => {
    try {
      if (!isEmpty(subProject) && !isEmpty(activeSubProject)) {
        const response = await fetchSubProjects(
          activeMasterProject?.id
            ? activeMasterProject?.id
            : masterProjectId || "",
        );
        const matchedSubProject = find(response.data, {
          id: activeSubProject.id,
        } as any);
        if (matchedSubProject) {
          dispatch(setActiveSubProject(matchedSubProject));
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  useEffect(() => {
    initializeTabsComponent();
  }, [subProject]);

  useEffect(() => {
    dispatch(
      setTableFilters({
        orderTypeTab: activeTab,
      }),
    );
  }, [dispatch, activeTab]);

  return (
    <div className="sub-project-tabs">
      <Tabs
        items={items.filter((item) => item.visible)}
        defaultActiveKey={activeTab}
        onChange={(tabId) => {
          navigate({ search: `page=${tabId}` });
        }}
      />
    </div>
  );
};

export default memo(SubProjectDetailTabs);
