import { FormInstance } from "antd";
import { MutableRefObject } from "react";
import {
  IDataRoundProject,
  IShiftSchedule,
} from "services/data-round/data-round.interface";

export interface IDrStepNavigationProps {
  currentActiveTab: number;
}

export interface IDrProjectCreationProps {
  FormInstance: FormInstance;
  masterProjectId: string;
  projectId?: string;
  isEdit?: boolean;
  getDataRoundProject: () => void;
  projectDetail?: IDataRoundProject;
}

export interface IDrProjectCreationState {
  projectId: string | null;
  selectedValues: number[];
  currentActiveTab: number;
  loading: boolean;
  project: IDataRoundProject | null;
  team?: string[];
}

export interface IDrProjectBasicInfoProps {
  state: IDrProjectCreationState;
  setState: (value?: any) => void;
  FormInstance: FormInstance;
  shiftScheduleRef: MutableRefObject<IShiftSchedule[] | undefined>;
  startTimeRef: MutableRefObject<string | undefined>;
  projectId: string;
  isEditing: boolean;
  project?: IDataRoundProject | null;
  shiftCount?: number | null;
  setShiftCount?: (value: number | null) => void;
  masterProjectId?: string;
  frequencyRef: MutableRefObject<DataRoundFrequncyEnum>;
  selectedDaysRef: MutableRefObject<DataRoundDaysEnum[]>;
  currentRoundIdRef: MutableRefObject<string | null>;
}

export enum DataRoundFrequncyEnum {
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
}

export enum DataRoundDaysEnum {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6,
}
