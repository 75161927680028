// Formats the repeat frequency for a work order based on the day of the month and frequency.
const formatDateFrequency = (
  date: string | number,
  frequency: string | number,
) => {
  if (date === 1 && frequency === 1) {
    return "This work order repeats on the first day of every month.";
  }
  if (frequency === 1) {
    return `This work order repeats on the ${date} day of every month.`;
  }
  return `This work order repeats on the ${date} day of every ${frequency} months.`;
};

// Formats the repeat frequency for a work order based on a specific day of the week and frequency.
const formatDayFrequency = (
  frequency: string | number,
  day: string | number,
  week: string | number,
) => {
  if (frequency === 1) {
    return `This work order repeats every month on ${week} week of ${day}.`;
  }
  return `This work order repeats every ${frequency} months on ${week} week of the ${day}.`;
};

// Renders the work order repetition based on the frequency type (WEEKLY, MONTHLY, DAILY) and other parameters.
export const renderWorkOrderRepeats = (record: any) => {
  const { frequencyType, dayType, date, frequency, day, week } = record ?? {};

  switch (frequencyType) {
    case "WEEKLY":
      return `This work order repeats weekly every ${day}.`;

    case "MONTHLY":
      if (dayType === "date") {
        return formatDateFrequency(date, frequency); // Handle repeat by date.
      }
      if (dayType === "day") {
        return formatDayFrequency(frequency, day, week); // Handle repeat by week and day.
      }
      break;

    case "DAILY":
      return "This work order repeats everyday."; // Handle daily repetition.

    default:
      return null;
  }
};
