//libs
import { memo, useState } from "react";
import { Col, Pagination, Row, Space, Spin, Typography } from "antd";
import { debounce, isEmpty } from "lodash";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

//components
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import AlectifyInput from "components/shared/input";
import SearchIcon from "components/icons/SearchIcon";
import AlectifyButton from "components/shared/button";
import ProcedureCard from "./procedures-card/ProceduresCard";
import ProcedureCreateEdit from "../procedure-creation";
import AlectifyEmptyState from "components/shared/empty/AlectifyEmptyState";

//interfaces
import { IProcedure } from "services/procedures/procedures.interface";
import { IProceduresListingProps } from "./ProceduresListing.interface";
import { IParams } from "redux/interfaces";

//constants
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import AlectifyText from "static/texts.json";
import { PAGINATION } from "constants/index";

//services
import { getProcedures } from "redux/components/procedures/sources";
import { IRootState } from "redux/rootReducer";

//styles
import "./ProcedureListing.scss";

const ProceduresListing: React.FC<IProceduresListingProps> = ({
  FormInstance,
  procedures,
  meta,
  fetching,
}) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string>("");
  const [pagination, setPagination] = useState<{ [key: string]: number }>({
    page: PAGINATION.DEFAULT_START_PAGE,
    limit: PAGINATION.DEFAULT_PAGE_SIZE,
  });

  const { activeMasterProject } = useSelector((state: IRootState) => ({
    activeMasterProject: state.common.activeMasterProject,
  }));

  const fetchProcedures = debounce((options?: IParams) => {
    let params: IParams = {};
    if (!isEmpty(options)) {
      params = {
        page: options.page,
        limit: options.limit,
        search: options.search,
        projectIds: options?.projectIds || null,
      };
    }
    dispatch(getProcedures(params));
  }, 500);

  const onSearch = (search: string) => {
    const lowerCaseSearch = search.toLowerCase();
    setSearchText(lowerCaseSearch);
    setPagination({
      page: PAGINATION.DEFAULT_START_PAGE,
      limit: pagination.limit,
    });
    fetchProcedures({
      page: PAGINATION.DEFAULT_START_PAGE,
      limit: pagination.limit,
      search: lowerCaseSearch || undefined,
      projectIds: activeMasterProject?.id ? [activeMasterProject.id] : null,
    });
  };

  const onPaginationChange = (page: number, pageSize: number) => {
    setPagination({
      page,
      limit: pageSize,
    });
    fetchProcedures({
      page,
      limit: pageSize,
      search: searchText,
      projectIds:
        (activeMasterProject?.id && [activeMasterProject?.id]) || null,
    });
  };

  const openCreationDrawer = (
    isEdit: boolean = false,
    procedure?: IProcedure,
  ) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.DRAWER_PROCEDURE_CREATION,
      width: 1471,
      title: isEdit
        ? AlectifyText.UPDATE_PROCEDURE
        : AlectifyText.ADD_NEW_PROCEDURE,
      showFooter: false,
      closeIcon: <CloseOutlined />,
      closable: true,
      onClose: () => {
        fetchProcedures({
          page: meta.currentPage,
          limit: meta.itemsPerPage,
          search: searchText,
          projectIds:
            (activeMasterProject?.id && [activeMasterProject?.id]) || null,
        });
        FormInstance.resetFields();
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.DRAWER_PROCEDURE_CREATION,
        });
      },
      children: (
        <ProcedureCreateEdit
          FormInstance={FormInstance}
          isEdit={isEdit}
          procedure={procedure || null}
          searchText={searchText}
        />
      ),
    });
  };

  return (
    <Spin spinning={fetching}>
      <Row
        justify={"space-between"}
        align={"middle"}
        className="alectify-procedure-listing-container"
      >
        <Col span={12}>
          <AlectifyInput
            type="text"
            prefix={<SearchIcon />}
            name="search"
            placeholder="Search"
            className="alectify-procedure-search m-0"
            onChange={(e) => onSearch(e.target.value)}
            allowClear
          />
        </Col>
        <Col span={12} className="text-align-right">
          <Space size={20}>
            <AlectifyButton
              name="procedure-create-new-btn"
              type="primary"
              text={AlectifyText.CREATE_PROCEDURE}
              onClick={() => openCreationDrawer(false)}
              icon={<PlusOutlined />}
            />
          </Space>
        </Col>
        <Col span={24} className="alectify-procedure-listing">
          {!isEmpty(procedures) ? (
            <Row justify={"start"} align={"middle"}>
              <Col span={24}>
                <Row justify={"start"} align={"middle"} gutter={16}>
                  {procedures.map((procedure: IProcedure) => (
                    <Col
                      key={procedure.id}
                      xl={6}
                      lg={8}
                      sm={12}
                      xs={24}
                      className="mt-10"
                    >
                      <ProcedureCard
                        procedure={procedure}
                        onEdit={openCreationDrawer}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          ) : (
            <AlectifyEmptyState />
          )}
        </Col>
        <Col span={24} className="text-align-right mt-10">
          {!isEmpty(procedures) && (
            <div className="d-flex justify-content-space-between align-items-center">
              {meta.totalItems > 0 && (
                <Typography.Text>
                  {meta.totalItems > 0 && (
                    <Typography.Text>
                      {`${
                        (meta.currentPage - 1) * meta.itemsPerPage + 1
                      } - ${Math.min(
                        meta.currentPage * meta.itemsPerPage,
                        meta.totalItems,
                      )} of ${meta.totalItems}`}
                    </Typography.Text>
                  )}
                </Typography.Text>
              )}

              <Pagination
                total={meta.totalItems}
                current={meta.currentPage}
                pageSize={meta.itemsPerPage}
                onChange={onPaginationChange}
                showSizeChanger
              />
            </div>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default memo(ProceduresListing);
