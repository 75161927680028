import { PM_STATUS } from "enums";
import { isEmpty } from "lodash";
import { isExternalUser } from "utils/helpers";
import { PM_EDITING_TYPES } from "../pm-create-edit-form/PMCreateEditForm.constants";
import AlectifyText from "static/texts.json";
import { ITaskActionItems } from "./TasksTable.interface";

export const actionItems = (action: ITaskActionItems) => {
  const items = [];
  const divider = {
    type: "divider",
  };
  !isEmpty(getOptionsForStatus(action)) &&
    items.push({
      key: "1",
      type: "group",
      // label: "Change State",
      children: getOptionsForStatus(action),
    });
  !action.pmDetailPageActive &&
    items.push(!isEmpty(getOptionsForStatus(action)) && divider, {
      key: "2",
      type: "group",
      // label: "Take Action",
      children: getOptionsForTakeAction(action),
    });
  if (
    !isExternalUser(action.currentUser) &&
    action.task?.status !== PM_STATUS.COMPLETED &&
    action.task?.status !== PM_STATUS.SKIPPED
  ) {
    items.push(divider, {
      key: "2",
      type: "group",
      // label: "Change Requirements",
      children: getOptionsForChangeRequirements(action),
    });
  }
  if (action.showDetailPageLink) {
    items.push({
      key: "3",
      type: "group",
      // label: "Change Requirements",
      children: [
        {
          label: AlectifyText.GO_TO_DETAIL,
          key: "detailPage",
          onClick: () =>
            action.goToPmDetail && action.goToPmDetail(action.task),
        },
      ],
    });
  }

  /*   if (action.pmDetailPageActive) {
    items.push({
      key: "4",
      type: "group",
      children: [
        {
          label: "Download PDF",
          key: "download-pdf",
          onClick: () => {
            action.downloadPDF && action.downloadPDF();
          },
        },
      ],
    });
  } */
  return items;
};

export const getOptionsForStatus = (action: ITaskActionItems): any[] => {
  if (!isEmpty(action.task)) {
    const { status } = action.task;
    const approverCheck = action?.task?.approvers.some(
      (approver: any) => approver.user.id === action.currentUser.id,
    );

    switch (status) {
      case PM_STATUS.COMPLETED:
      case PM_STATUS.SKIPPED:
        return [
          {
            label: AlectifyText.REOPEN,
            key: "reopen",
            onClick: () => action.openWarningmodal(),
          },
        ];
      case PM_STATUS.PENDING:
        return [
          {
            label:
              action.taskStatusStepIcon &&
              action.taskStatusStepIcon(
                action.task.status,
                PM_STATUS.WAITING_FOR_REVIEW,
              ),
            key: "submitForReview",
            onClick: () => action.openSubmitForReviewModal(action.task),
          },
          {
            label: AlectifyText.VOID,
            key: "skip",
            onClick: () => action.updateStatus(action.task, PM_STATUS.SKIPPED),
          },
        ];
      case PM_STATUS.WAITING_FOR_REVIEW:
        return [
          status === PM_STATUS.WAITING_FOR_REVIEW &&
            approverCheck && {
              label: AlectifyText.REVIEW_COMPLETE,
              key: "complete",
              onClick: () =>
                action.updateStatus(action.task, PM_STATUS.COMPLETED),
            },
        ].filter(Boolean); // Remove undefined entries
      default:
        return [];
    }
  }
  return [];
};

export const getOptionsForTakeAction = (action: ITaskActionItems): any[] => {
  if (!isEmpty(action.task)) {
    return [
      {
        label: "Draw Spare Parts",
        key: "spare-parts",
        onClick: () =>
          action.openSpareParts && action.openSpareParts(action.task),
      },
      !isEmpty(action.task.procedure) && {
        label: AlectifyText.PROCEDURE_CHECKLIST,
        key: "procedureCheckList",
        onClick: action.openProcedureCheckListDrawer,
      },
      {
        key: "attachFile",
        label: "Upload Attachments",
        onClick: () => action.onUpload(),
      },
    ];
  }
  return [];
};

export const getOptionsForChangeRequirements = (
  action: ITaskActionItems,
): any[] => {
  if (!isEmpty(action.task)) {
    return [
      {
        key: "copy",
        label: "Copy",
        onClick: () =>
          action.onEdit(
            null,
            false,
            PM_EDITING_TYPES.ALL_EVENTS,
            action.task,
            true,
          ),
      },
      action.task.isRecurring
        ? {
            label: "Edit",
            key: "edit",
            children: [
              {
                key: "1-1",
                label: "This Event",
                onClick: () =>
                  action.onEdit(
                    null,
                    true,
                    PM_EDITING_TYPES.THIS_EVENT,
                    action.task,
                  ),
              },
              action.task.isRecurring &&
                !action.task.isReopened && {
                  key: "1-2",
                  label: "All Events",
                  onClick: () =>
                    action.onEdit(
                      null,
                      true,
                      PM_EDITING_TYPES.ALL_EVENTS,
                      action.task,
                    ),
                },
            ],
          }
        : {
            label: "Edit",
            key: "edit",
            onClick: () =>
              action.onEdit(
                null,
                true,
                PM_EDITING_TYPES.THIS_EVENT,
                action.task,
              ),
          },
      !action.task.isReopened && {
        label: AlectifyText.DELETE,
        key: "delete",
        onClick: () => action.removeTask(action.task.id),
      },
    ];
  }
  return [];
};
