import React, { RefObject } from "react";
import { RowClassName } from "rc-table/lib/interface";
import { RowSelectionType } from "antd/lib/table/interface";
import { ColumnProps, ColumnGroupType } from "antd/lib/table";
import { DateRange } from "../maintenance-prediction/MaintenancePrediction.interface";
import { DataRef } from "../assets-table/AssetsTable.interface";

export type SizeType = "small" | "middle" | "large" | undefined;

export interface IFilterOption {
  value: string;
  label: string;
}

export interface AlectifyTableFilters {}
export interface IAlectifyTableProps<RecordType = unknown> {
  expandable?: any;
  activeTaskMaintenanceSearch?: boolean;
  enableFilters?: {
    createdAt: boolean;
    dueDate: boolean;
    status: boolean;
    taskCategory: boolean;
  };
  toggleChange?: (value: string) => void;
  toggleOptions?: IFilterOption[];
  toggleLabel?: string;
  addDocumentButton?: boolean;
  aIBotHeader?: boolean;
  searchDataRef?: RefObject<DataRef>;
  id?: string | null;
  className?: string;
  size?: SizeType;
  dataSource: RecordType[];
  total: number;
  showSizeChanger?: boolean;
  rowSelection?: boolean;
  loading?: boolean;
  showPagination?: boolean;
  selectionType?: RowSelectionType;
  defaultPageSize?: number;
  multipleDeleteOption?: boolean;
  scroll?: any;
  bordered?: boolean;
  columns: ColumnProps<any>[] | ColumnGroupType<any>[];
  showSearch?: boolean;
  downloadable?: boolean;
  filters?: ITableFilters;
  defaultFilterValue?: string | any;
  customSearchKey?: string;
  noIcontainsSearch?: boolean;
  tableTitle?: string;
  extraButton?: JSX.Element;
  selectedRowKeys?: string[];
  displayRowClass?: RowClassName<RecordType>;
  onRowClick?: (record: RecordType, index: number | undefined) => void;
  onDataFetch: (record: IPagination) => void;
  onEmailShare?: (record: RecordType) => void;
  onDownloadZip?: (record: any) => void;
  onMultipleDelete?: (record: RecordType) => void;
  onRowSelectionAction?: (records: RecordType[], keys: React.Key[]) => void;
  documentType?: string;
  columnFilterAsString?: boolean;
  fetchSelectedRowKeys?: (value: any) => void;
  taskSorting?: boolean | undefined;
  getDateRangeFromChild?: (value: DateRange) => void;
  setShowMaintenanceDetail?: (value: boolean) => void;
  getCheckboxProps?: boolean;
  locale?: any;
  filterType?: ITableFilterType;
  searchPlaceholder?: string;
}
export enum ITableFilterType {
  DROPDOWN = "DROPDOWN",
  BUTTONS = "BUTTONS",
}
export interface ITableFiltersOption {
  label: string;
  value: string;
}

export interface ITableFilters {
  key: string;
  options: ITableFiltersOption[];
  defaultValue?: string;
}

export interface IPagination {
  id?: string | undefined | null;
  page?: number | undefined | null;
  per_page?: number | undefined | null;
  orderingColumn?: string | undefined | null;
  pageSize?: number | undefined | null;
  search?: string;
  [key: string]: string | number | undefined | null;
}

export interface IEmailFormValues {
  emails: string[];
}

export interface IAlectifyTableFiltersProps {
  enableFilters?: {
    createdAt: boolean;
    dueDate: boolean;
    status: boolean;
    taskCategory: boolean;
  };
  tableType?: string;
}
