import DataRoundsCalendar from "components/shared/data-rounds-reports/DataRoundsCalendar";
import { memo } from "react";
import { useParams } from "react-router-dom";

const DataRoundsCalendarTab = () => {
  const { projectId } = useParams();

  return (
    <>
      <DataRoundsCalendar projectId={projectId || null} />
    </>
  );
};

export default memo(DataRoundsCalendarTab);
