import { get, isEmpty } from "lodash";
import { Breadcrumb, TabsProps } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { memo, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { getSubProjects } from "redux/components/sub-project/sources";
import { ROUTES } from "routes/Routes.constants";
import AlectifyHeading from "components/shared/heading";
import MasterProjectDetailCard from "./detail-card";
import SubProjectListing from "./sub-project-listing";
import AlectifyButton from "components/shared/button";
import AlectifyText from "static/texts.json";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import AlectifyDrawer from "components/drawer";
import { useForm } from "antd/es/form/Form";
import { CreateEditSubProject } from "components/shared/create-edit-sub-project";
import { isFeatureEnabled } from "utils/helpers";
import { FEATURE_FLAGS } from "redux/components/Auth";
import AlectifyTabs from "components/shared/tabs";
import UsersManagementTable from "components/shared/users-table/UserManagementTable";
import TeamsTable from "components/shared/teams-table";
import DataRoundProjectListing from "pages/data-round/dr-project-listing/DataRoundProjectListing";
import "./style.scss";

const MasterProjectDetail: React.FC<any> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const project = useSelector(
    ({ common }: IRootState) => common.activeMasterProject,
  );
  const subProject = useSelector((state: IRootState) => state.subProject);
  const { masterProjectId } = useParams();
  const [FormInstance] = useForm();
  const location = useLocation();
  const activeTab =
    new URLSearchParams(location.search).get("page") || "sub-projects";
  const onClose = () => {
    FormInstance.resetFields();
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.CREATE_SUB_PROJECT_DRAWER,
    });
  };

  const items: TabsProps["items"] = [
    {
      key: "sub-projects",
      label: <>Asset Category List {`(${subProject.data.length})`}</>,
      children: (
        <>
          <div className="d-flex justify-content-space-between align-items-center">
            <AlectifyHeading
              className="mt-20"
              buttonText="Create New"
              title={`${AlectifyText.LIST_ASSET_CATEGORY} (${get(
                subProject,
                "meta.total_count",
                subProject?.data?.length,
              )})`}
            />
            {isFeatureEnabled(FEATURE_FLAGS.MANAGE_PROJECT) && (
              <AlectifyButton
                text={AlectifyText.NEW_ASSET_CATEGORY}
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  DrawerServiceInstance.open(AlectifyDrawer, {
                    width: "auto",
                    title: AlectifyText.CREATE_ASSET_CATEGORY,
                    name: DRAWER_CONSTANTS.CREATE_SUB_PROJECT_DRAWER,
                    showFooter: false,
                    onClose: onClose,
                    closeIcon: <CloseOutlined />,
                    closable: true,
                    destroyOnClose: true,
                    children: (
                      <CreateEditSubProject
                        FormInstance={FormInstance}
                        masterProjectId={masterProjectId}
                      />
                    ),
                  });
                }}
              />
            )}
          </div>
          <SubProjectListing subProjects={subProject.data} />
        </>
      ),
    },
    {
      key: "users",
      label: <>{AlectifyText?.USER_MANAGEMENT}</>,
      children: <UsersManagementTable />,
    },
    {
      key: "teams",
      label: <>{AlectifyText?.TEAM_MANAGEMENT}</>,
      children: <TeamsTable />,
    },
  ];

  useEffect(() => {
    if (location.pathname.includes(ROUTES.PROJECT)) {
      if (!isEmpty(masterProjectId) || masterProjectId !== "null") {
        dispatch(getSubProjects(masterProjectId));
      }
    }
    if (isEmpty(project)) {
      if (location.pathname.includes(ROUTES.DATA_ROUND)) {
        navigate(ROUTES.DATA_ROUND);
      } else {
        navigate(ROUTES.PROJECT);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterProjectId, project, location.pathname]);

  return (
    <div className="rounds-detail-main">
      <Breadcrumb
        className="mb-20"
        items={[
          {
            title: AlectifyText.SITES,
            href: `#`,
            onClick: () => navigate(`${ROUTES.PROJECT}`),
          },
          { title: project?.name },
        ]}
      />

      <MasterProjectDetailCard />

      {location.pathname.includes(ROUTES.DATA_ROUND) ? (
        <div className="mt-20">
          {masterProjectId && (
            <DataRoundProjectListing masterProjectId={masterProjectId} />
          )}
        </div>
      ) : (
        <AlectifyTabs
          items={items}
          currentTab={activeTab}
          className="mt-10"
          onTabChange={(tabId) => navigate({ search: `page=${tabId}` })}
        />
      )}
    </div>
  );
};

export default memo(MasterProjectDetail);
