import {
  WORK_ORDER_EDITING_TYPES,
  WORK_ORDER_FORM_FIELD_TYPES,
} from "./WorkOrderCreateEdit.constants";
import {
  IWorkOrderCreateEditProps,
  IWorkOrderInitialState,
} from "./WorkOrderCreateEdit.interface";
import DayJs from "dayjs";
import AlectifyText from "static/texts.json";
import { ITask } from "redux/components/tasks";
import { isEmpty } from "lodash";
import { MaintenanceCategoriesEnum } from "enums";
import { convertArrayToStrings } from "utils/helpers";

/**
 * Creates Work order payload for the creation and update.
 * @param { IWorkOrderInitialState } state - state of the work order creation component.
 * @param { any } values - values from the work order creation form.
 * @param { IWorkOrderCreateEditProps } props - props of the work order creation component.
 * @return { any } payload.
 */
export const createWorkOrderPayload = (
  state: IWorkOrderInitialState,
  values: any,
  props: IWorkOrderCreateEditProps,
): any => {
  const teamInfo =
    state?.selectedMembertype === "users" && state.selectedTeamMembers
      ? {
          teamMembers: state?.selectedTeamMembers?.map(
            (member: any) => member.id,
          ),
        }
      : state?.selectedMembertype === "teams" && state.selectedTeamMembers
      ? { teamId: state?.selectedTeamId }
      : undefined;
  const payload = {
    ...values,
    ...teamInfo,
    projectId: state.selectedMasterProject?.id,
    subProjectId: state.selectedSubProject?.id,
    priority: values.priority ? "CRITICAL" : "NORMAL",
    dueDate: DayJs(values["dueDate"]).format(),
    frequency: values.frequency || values.frequency_day || undefined,
    isRecurring: state.isRecurring,
    frequencyType: values.frequencyType?.toUpperCase(),
    areaIds: state.selectedAssetPackage,
    assetIds: state.selectedAssets,
    procedureLibraryId:
      state.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibrary?.id ||
      null,
    assets: null,
    assetLevel:
      isEmpty(state.selectedAssets) && isEmpty(state.selectedAssetPackage)
        ? "generic"
        : "asset",
    taskCategory: state.taskCategory,
  };
  if (!props.editing || props.copying) {
    payload.frequency =
      values.frequency ||
      values.frequency_day ||
      state.taskDetails?.frequency ||
      undefined;
    payload.procedureLibraryId =
      state.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibrary?.id ||
      state.selectedProcedureLibrary?.id ||
      null;
    payload.subProjectId = state.selectedSubProject?.id;
  }
  if (props.editing && !props.copying) {
    // delete payload.assignees;
    // delete payload.approvers;

    payload.frequency =
      values.frequency ||
      values.frequency_day ||
      state.taskDetails?.frequency ||
      undefined;
    payload.changeAllFuturePM =
      props.editType === WORK_ORDER_EDITING_TYPES.ALL_EVENTS;
    payload.procedureLibraryId =
      state.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibrary?.id ||
      state.selectedProcedureLibrary?.id ||
      null;
    /* Clearing the clutter which aren't need in the edit case. */
    delete payload.subProject;
    delete payload.masterProject;
    // delete payload.assetIds;
    // delete payload.areaIds;
  }
  /* deleting this key as it won't be needed in payload. created this key for only getting the value of the identical field with different case. */
  delete payload.frequency_day;
  delete payload.teamMemberProp;
  // Remove keys with undefined values
  const filteredPayload = Object.entries(payload).reduce(
    (acc: any, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );
  if (props.copying) {
    filteredPayload[WORK_ORDER_FORM_FIELD_TYPES.ASSIGNEES] =
      convertArrayToStrings(payload[WORK_ORDER_FORM_FIELD_TYPES.ASSIGNEES]);
    filteredPayload[WORK_ORDER_FORM_FIELD_TYPES.APPROVERS] =
      convertArrayToStrings(payload[WORK_ORDER_FORM_FIELD_TYPES.APPROVERS]);
  }
  return filteredPayload;
};

/**
 * Initializes form in case of creation, edit and copying with pre existing values.
 * @param { IWorkOrderCreateEditProps } props - props of the work order creation component.
 * @param { ITask | null } taskDetails - Existing task object.
 * @return { void } - this function returns nothing its a void;
 */
export const initializeWorkOrderForm = (
  props: IWorkOrderCreateEditProps,
  taskDetails: ITask | null,
): void => {
  if (!isEmpty(taskDetails)) {
    const pmAssigneesToMap = taskDetails?.assignees;
    const assigneesValue =
      pmAssigneesToMap &&
      pmAssigneesToMap.map((assignee: any) => ({
        ...assignee,
        label: `${assignee.user.first_name} ${assignee.user.last_name}`,
        name: assignee.user.first_name,
        value: assignee.user.id,
      }));
    const pmTeamMember = isEmpty(taskDetails?.teamMembers)
      ? taskDetails?.team?.projectTeamMembers
      : taskDetails?.teamMembers;
    const teamSValue = pmTeamMember?.map((member: any) => member?.user?.id);

    const pmApproversToMap = taskDetails?.approvers;
    const approversValue =
      pmApproversToMap &&
      pmApproversToMap.map((approvers: any) => ({
        ...approvers,
        label: `${approvers.user.first_name} ${approvers.user.last_name}`,
        name: approvers.user.first_name,
        value: approvers.user.id,
      }));
    props.FormInstance.setFields([
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.MASTER_PROJECT,
        value: taskDetails?.project.name,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.SUB_PROJECT,
        value: taskDetails?.subProject.name,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.WORK_TITLE,
        value: taskDetails.workTitle || null,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.TASK_PRIORITY,
        value: taskDetails.priority === "CRITICAL",
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.DETAILS,
        value: taskDetails.detail,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.TASK_CATEGORY,
        value:
          taskDetails.taskCategory ===
            MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE ||
          taskDetails.taskCategory ===
            MaintenanceCategoriesEnum.PREVENTIVE_MAINTENANCE
            ? taskDetails.taskCategory
            : "TASK",
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.ASSIGNEES,
        value: assigneesValue,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.APPROVERS,
        value: approversValue,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.DUE_DATE,
        value: DayJs(taskDetails.dueDate),
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.DATE,
        value: taskDetails?.date || null,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.DAY,
        value: taskDetails?.day || null,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.WEEK,
        value: taskDetails?.week || null,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY,
        value: taskDetails?.frequency || null,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY_DAY,
        value: taskDetails?.frequency || null,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.ASSETS,
        value: "value",
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.TEAM_MEMBERS_LIST,
        value: teamSValue,
      },
      {
        name: "teamMemberProp",
        value: taskDetails?.team
          ? [taskDetails?.team.id]
          : taskDetails.teamMembers
          ? taskDetails.teamMembers.map((d: any) => d.user.id)
          : [],
      },
    ]);
  }
};

/**
 * Gets the selected assets name combined to show the on the button.
 * @param { any[] } selectedAssets - selected assets array.
 * @param { any[] } selectedAssetPackage - selected asset package array.
 * @return { string } returns string;
 */
export const getSelectedAssetsText = (
  selectedAssets: any[] | null,
  selectedAssetPackage: any[] | null,
): string => {
  // Combine selected asset names and package names
  const selectedNames = [
    ...(selectedAssets?.map(
      (asset) => asset.tag_name || asset.name || asset.asset?.name || "-",
    ) || []),
    ...(selectedAssetPackage?.map(
      (pkg) => pkg.package_name || pkg.name || pkg.area?.name || "-",
    ) || []),
  ];

  // If there are more than 2 items, show the count
  if (selectedNames.length > 1) {
    return `${selectedNames.slice(0, 1).join(", ")} (+${
      selectedNames.length - 1
    } Assets)`;
  }

  // If there are 2 or fewer, just join them with commas
  return selectedNames.join(", ") || AlectifyText.ADD_ASSETS;
};
