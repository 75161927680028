import { Col, Radio, Row } from "antd";
import { IIncidentBasicInfoProps } from "../IncidentReportCreateEdit.interface";
import AlectifyInput from "components/shared/input";
import {
  IR_FIELD_LABELS,
  IR_FIELD_NAMES,
} from "../IncidentReportCreateEdit.constants";
import AlectifyText from "static/texts.json";
import { MESSAGES } from "constants/messages";
import FormItem from "antd/es/form/FormItem";
import AlectifyDatePicker from "components/shared/datepicker";
import SelectMasterProject from "components/shared/master-project-select";
import SelectSubProject from "components/shared/sub-project-select/SelectSubProject";
// import TextEditor from "components/text-editor/TextEditor";
import dayjs from "dayjs";

const IncidentBasicInfo: React.FC<IIncidentBasicInfoProps> = (
  props: IIncidentBasicInfoProps,
) => {
  return (
    <>
      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <span className="alectify-work-order-section-card-heading">
            1. Incident Details
          </span>
        </Col>
        <Col span={12} className="text-align-right">
          <span className="alectify-work-order-section-card-counter">1/5</span>
        </Col>
      </Row>
      <div className="alectify-work-order-section-card">
        <Row justify={"center"} align={"middle"} gutter={10}>
          <Col span={24}>
            <AlectifyInput
              name={IR_FIELD_NAMES.TITLE}
              prefix={
                !props.editing ? (
                  <strong>
                    {(props.selectedMasterProject?.site_code &&
                      `${props.selectedMasterProject?.site_code}:`) ||
                      ""}
                  </strong>
                ) : (
                  <></>
                )
              }
              placeholder={AlectifyText.TITLE}
              label={AlectifyText.TITLE}
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.TITLE,
                  ),
                },
              ]}
              type="text"
            />
          </Col>
          <Col span={24}>
            <FormItem name={IR_FIELD_NAMES.TYPE} label={IR_FIELD_LABELS.TYPE}>
              <Radio.Group>
                <Radio value={"Utility Event"}>Utility Event</Radio>
                <Radio value={"Incident"}>Incident</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
          <Col span={24}>
            <Row justify={"start"} align={"middle"} gutter={16}>
              <Col span={12}>
                <FormItem
                  name={IR_FIELD_NAMES.DATE}
                  label={`${IR_FIELD_LABELS.DATE} (24 hrs)`}
                >
                  <AlectifyDatePicker
                    showTime={
                      {
                        format: "HH:mm", // Format for hours and minutes
                        defaultValue: dayjs("00:00", "HH:mm"), // Default time value
                      } as any
                    }
                    format="YYYY-MM-DD HH:mm" // Date and time format
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <AlectifyInput
                  name={IR_FIELD_NAMES.INCIDENT_NUMBER}
                  placeholder={AlectifyText.INCIDENT_NUMBER}
                  label={AlectifyText.INCIDENT_NUMBER}
                  maxLength={15}
                  /*   rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.INCIDENT_NUMBER,
                      ),
                    },
                  ]} */
                  type="text"
                />
              </Col>

              {/* <Col span={8}>
                <FormItem
                  name={IR_FIELD_NAMES.TIME}
                  label={IR_FIELD_LABELS.TIME}
                >
                  <TimePicker onChange={(e) => console.log(e)} />
                </FormItem>
              </Col> */}
            </Row>
          </Col>
          <Col span={24}>
            <Row justify={"center"} align={"middle"} gutter={[16, 16]}>
              <Col span={12}>
                <SelectMasterProject
                  onChange={() => {}}
                  onSelect={props.onSelectMasterProject}
                  defaultValue={props.selectedMasterProject?.id}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.SITE,
                      ),
                    },
                  ]}
                  disabled={props.editing}
                />
              </Col>
              <Col span={12}>
                <SelectSubProject
                  onSelect={props.onSelectSubProject}
                  masterProjectId={props.selectedMasterProject?.id || ""}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.ASSET_CATEGORY,
                      ),
                    },
                  ]}
                  disabled={props.editing}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default IncidentBasicInfo;
