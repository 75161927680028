//libs
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { useLocation, useParams } from "react-router-dom";
//internal
import TasksTable from "components/shared/tasks-table";
import { PM_TYPES } from "redux/components/pm-external";
// import { fetchTaskDetails } from "services/tasks/tasks.service";

//interface
interface ITasksProps {
  scroll?: {
    x?: number | string;
    y?: number | string;
  };
  isMasterPM: boolean;
}
const Tasks: React.FC<ITasksProps> = (props: ITasksProps) => {
  const location = useLocation();
  const { masterProjectId, subProjectId } = useParams();
  const taskId = new URLSearchParams(location.search).get("taskId");

  const openTaskDetailDrawer = async (id: string) => {
    // const response = await fetchTaskDetails({
    //   id,
    // });
    // if (response?.id) {
    //   DrawerServiceInstance.open(AlectifyDrawer, {
    //     width: 420,
    //     title: response.name || "-",
    //     name: DRAWER_CONSTANTS.DRAWER_TASK_DETAIL,
    //     onClose: () =>
    //       DrawerServiceInstance.close(AlectifyDrawer, {
    //         name: DRAWER_CONSTANTS.DRAWER_TASK_DETAIL,
    //       }),
    //     children: <TaskDetail taskId={response.id} />,
    //   });
    // }
  };

  useEffect(() => {
    if (!isEmpty(taskId)) {
      openTaskDetailDrawer(taskId || "");
    }
  }, [masterProjectId, subProjectId]);

  return (
    <TasksTable
      subProjectId={subProjectId}
      masterProjectId={masterProjectId}
      allowCreation
      scroll={{ y: "calc(100vh - 375px)" }}
      myWorkdOrder={false}
      type={PM_TYPES.TASK}
      isMasterPM={props.isMasterPM}
    />
  );
};

export default Tasks;
