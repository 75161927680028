import { Dispatch } from "react";
import { IParams, SourceActionType } from "redux/interfaces";
// import { notification } from "antd";
// import { MESSAGES } from "constants/messages";
import { getPmsCounts } from "redux/components/pm-internal/sources";
import { ITaskResponse, actions } from "..";
import { fetchTasksService } from "services/tasks/tasks.service";
// import { PM_TYPES } from "redux/components/pm-external";

export const fetchTasks: SourceActionType = (
  masterProjectId: string,
  subProjectId: string,
  params: IParams,
  type: string,
  isMasterPM: boolean = false,
  requestId?: string | null,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.showTasksLoader(true));
      const response: ITaskResponse = await fetchTasksService(
        masterProjectId,
        subProjectId,
        params,
        type,
        isMasterPM,
        requestId,
      );
      if (response?.status) {
        dispatch(actions.showTasksLoader(false));
        dispatch(actions.getProjectTasks(response));
        dispatch(getPmsCounts(masterProjectId, subProjectId, params));
      }
    } catch (ex: any) {
      dispatch(actions.showTasksLoader(false));
    }
  };
};
