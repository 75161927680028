import { Tooltip } from "antd";
import AlectifyText from "static/texts.json";
import BulbIcon from "./BulbIcon";

const FortuneGlass: React.FC<{ onClick?: () => void; isActive?: boolean }> = ({
  onClick,
  isActive,
}) => {
  return (
    <div onClick={onClick}>
      <Tooltip title={AlectifyText.BREAKDOWN_PREDICTION}>
        <BulbIcon className="bulb-icon" active={isActive} />
      </Tooltip>
    </div>
  );
};

export default FortuneGlass;
