import { Col, Row, Tooltip } from "antd";
import { IIncidentImpactProps } from "../IncidentReportCreateEdit.interface";
import AlectifySelect from "components/shared/select";
import { IR_FIELD_NAMES } from "../IncidentReportCreateEdit.constants";
import AlectifyText from "static/texts.json";
import { useEffect, useState } from "react";
import {
  getAffectedSystems,
  getIncidentImpacts,
} from "services/incident-reports/incident-reports.service";
import {
  IIncidentAffectedSystem,
  IIncidentImpact,
} from "services/incident-reports/incidnent-reports.interface";
import { isEmpty } from "lodash";
import AlectifyButton from "components/shared/button";
import { PlusOutlined } from "@ant-design/icons";
import { getSelectedAssetsText } from "pages/work-order/WorkOrderCreateEdit.helpers";
import { MESSAGES } from "constants/messages";

const IncidentImpact: React.FC<IIncidentImpactProps> = (
  props: IIncidentImpactProps,
) => {
  const [impactOptions, setImpactOptions] = useState<{
    data: IIncidentImpact[] | null;
    fetching: boolean;
  }>({
    data: null,
    fetching: false,
  });
  const [affectedSystem, setAffectedSystem] = useState<{
    data: IIncidentImpact[] | null;
    fetching: boolean;
  }>({
    data: null,
    fetching: false,
  });

  const getImpactList = async () => {
    setImpactOptions((prev) => ({ ...prev, fetching: true }));
    try {
      const response = await getIncidentImpacts(
        props.masterProjectId || props.incidentDetail?.project?.id || "",
      );
      if (response.status) {
        setImpactOptions({
          data: response.data,
          fetching: false,
        });
      }
    } catch (ex) {
      setImpactOptions((prev) => ({ ...prev, fetching: false }));
      console.log(ex);
    }
  };

  const getAffectedSystemList = async () => {
    setAffectedSystem((prev) => ({ ...prev, fetching: true }));
    try {
      const response = await getAffectedSystems(
        props.masterProjectId || props.incidentDetail?.project?.id || "",
      );
      if (response.status) {
        setAffectedSystem({
          data: response.data,
          fetching: false,
        });
      }
    } catch (ex) {
      setAffectedSystem((prev) => ({ ...prev, fetching: false }));
      console.log(ex);
    }
  };

  useEffect(() => {
    if (props.masterProjectId || props.incidentDetail?.project?.id) {
      getImpactList();
      getAffectedSystemList();
    }
  }, [props.masterProjectId]);

  return (
    <>
      <Row justify={"space-between"} align={"middle"} gutter={[16, 16]}>
        <Col span={12}>
          <span className="alectify-work-order-section-card-heading">
            3. Incident Impact
          </span>
        </Col>
        <Col span={12} className="text-align-right">
          <span className="alectify-work-order-section-card-counter">3/5</span>
        </Col>
      </Row>
      <div className="alectify-work-order-section-card">
        <Row justify={"center"} align={"middle"} gutter={10}>
          <Col span={24}>
            <Row justify={"start"} align={"middle"} gutter={16}>
              <Col span={8}>
                <AlectifySelect
                  name={IR_FIELD_NAMES.AFFECTED_SYSTEMS}
                  label={AlectifyText.AFFECTED_SYSTEM}
                  options={
                    (!isEmpty(affectedSystem.data) &&
                      affectedSystem.data?.map(
                        (affectedSystem: IIncidentAffectedSystem) => ({
                          label: affectedSystem.name,
                          value: affectedSystem.id,
                          key: affectedSystem.id,
                        }),
                      )) ||
                    []
                  }
                />
              </Col>
              <Col span={8}>
                <AlectifySelect
                  name={IR_FIELD_NAMES.INCIDENT_IMPACT}
                  label={AlectifyText.INCIDENT_IMPACT}
                  loading={impactOptions.fetching}
                  options={
                    (!isEmpty(impactOptions.data) &&
                      impactOptions.data?.map((impact: IIncidentImpact) => ({
                        label: impact.name,
                        value: impact.id,
                        key: impact.id,
                      }))) ||
                    []
                  }
                />
              </Col>
              <Col span={8}>
                <AlectifySelect
                  name={IR_FIELD_NAMES.PRIORITY}
                  label={AlectifyText.PRIORITY}
                  options={[
                    {
                      label: "Normal",
                      key: "normal",
                      value: "NORMAL",
                    },
                    {
                      label: "Critical",
                      key: "critical",
                      value: "CRITICAL",
                    },
                  ]}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.PRIORITY,
                      ),
                    },
                  ]}
                />
              </Col>
              <Col span={24} className="mb-10">
                <Tooltip
                  className="alectify-selected-asset-tooltip"
                  title={
                    !isEmpty(props.selectedAssets) ||
                    !isEmpty(props.selectedAssetPackage) ? (
                      <ol className="selected-documents-list">
                        {[
                          ...(props.selectedAssets as any),
                          ...(props.selectedAssetPackage as any),
                        ].map((equipment: any) => (
                          <li>
                            {equipment?.name ||
                              equipment.tag_name ||
                              equipment.name ||
                              equipment.package_name}
                          </li>
                        ))}
                      </ol>
                    ) : null
                  }
                >
                  <AlectifyButton
                    name="Assets"
                    icon={
                      (isEmpty(props.selectedAssetPackage) &&
                        isEmpty(props.selectedAssets) && <PlusOutlined />) || (
                        <></>
                      )
                    }
                    text={
                      getSelectedAssetsText(
                        props.selectedAssets,
                        props.selectedAssetPackage,
                      ) || AlectifyText.ADD_ASSETS
                    }
                    type="primary"
                    className="full-width wo-assets-btn"
                    onClick={props.equipmentModalHandler}
                    disabled={
                      isEmpty(props.selectedSubProject) || props.editing
                    }
                  />
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default IncidentImpact;
